@import '../../marketplace.css';

.recentlyListed {
  @apply --marketplaceContainerStyles;
  padding-block: 20px;
  position: relative;
  text-align: center;
  @media (--viewportMobileOnly) {
    text-align: left;
  }
}

.title {
  @apply --marketplaceH2FontStyles;
}

.listingCards {
  @apply --gridStyle;
  text-align: left;
  margin-block-end: 0;
}

.listingCard {
  height: 100%;
}

.listingContainer {
  width: 100%;
}
