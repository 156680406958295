@import '../../marketplace.css';

.root {
  flex-grow: 1;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  padding-block: var(--spacing-s)  var(--spacing-m);;
  @media (--viewportMedium) {
    padding-block: var(--spacing-m) var(--spacing-l);
  }
}

.form {
  flex-grow: 1;
}

.title {
  @apply --marketplaceH3FontStyles;
}
