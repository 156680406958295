@import '../../marketplace.css';

:root {
}

.root {
}

.pageTitle {
  width: auto;
  text-align: center;
}

.content {
}

.content > p {
  text-align: center;
}
