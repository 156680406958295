@import '../../marketplace.css';

:root {
  --contentPaddingSmall: 20px;
  --contentGapSmall: 20px;
  --contentPaddingLarge: 60px;
  --contentGapLarge: 36px;
}

.root {
  @apply --marketplaceContainerStyles;
  padding: var(--spacing-m) var(--spacing-s);
  text-align: center;
  @media (--viewportMedium) {
    padding: var(--spacing-xl);
  }
}

.container {
  display: flex;
  flex-flow: column;
  gap: var(--spacing-s);
  @media (--viewportSmall) {
    gap: var(--spacing-m);
  }
}

.images {
  display: flex;
  flex-flow: row;
  gap: var(--spacing-s);

  @media (--viewportSmall) {
    gap: var(--spacing-s);
  }

  @media (--viewportMedium) {
    gap: var(--spacing-s);
  }

  @media (--viewportLarge) {
    gap: var(--spacing-m);
  }

}

.images img {
  width: 100%;
  height: auto;
  flex-shrink: 0;
}

.images a:nth-child(3),
.images a:nth-child(4) {
  display: none;
  @media (--viewportMedium) {
    display: block;
  }
}

.button {
  @apply --marketplaceButtonStyles;
}
