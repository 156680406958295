@import '../../marketplace.css';

.root {
  @apply --marketplaceContainerNarrowStyles;
}

.heading {
  text-align: center;
}

.pageTitle {
  text-align: center;
}

.staticPageWrapper {
  @apply --marketplaceContainerNarrowStyles;
}

.extraPadding {
  height: var(--spacing-l);
}

.coverImage {
  width: 100%;
  height: 528px;
  border-radius: 4px;
  object-fit: cover;
  margin: 32px 0 40px;
}

.contentWrapper {
}

.contentSide {
  font-style: italic;

  @media (--viewportMedium) {
    width: 193px;
    margin-right: 103px;
    margin-top: 8px;
  }
}

.contentMain {
}

.contentMain ul {
  padding-left: 30px;
  list-style: circle;
}

.contentMain li {
  @apply --marketplaceBodyFontStyles;
}

.contentMain img {
  max-width: 100%;
}

.subtitle {
  @apply --marketplaceH3FontStyles;
  margin-top: 48px;
  margin-bottom: 24px;
}


hr {
  border: 0;
  border-top: 1px var(--c-grey-100) solid;
  margin-block: var(--spacing-m);

  @media (--viewportMedium) {
    margin-block: var(--spacing-l);
  }
}
