@import '../../marketplace.css';

.container {
  @apply --marketplaceContainerNarrowStyles;
}

.title {
  text-align: center;
}

.copy {
  text-align: center;
}
