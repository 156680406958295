@import '../../marketplace.css';

:root {
}

.root {
  @apply --marketplaceContainerStyles;
  position: relative;
  text-align: center;

  padding-block: var(--spacing-m);
  @media (--viewportMedium) {
    padding-block: var(--spacing-l);
  }
}

.root:global(.yellow) {
  color: var(--c-yellow-500);
}

.root:global(.pink) {
  color: var(--c-pink-500);
}

.root:global(.green) {
  color: var(--c-green-500);
}

.root:global(.negative) {
  margin-block: var(--spacing-s);
  color: #fff;
  @media (--viewportMedium) {
    padding-block: var(--spacing-l);
  }
}

.root:global(.negative.yellow) {
  background-color: var(--c-yellow-500);
}
.root:global(.negative.pink) {
  background-color: var(--c-pink-500);
}
.root:global(.negative.green) {
  background-color: var(--c-green-500);
}

.testimonial {
  position: relative;
  max-width: 840px;
  margin: 0 auto;
}

.testimonial blockquote {
  padding: 58px 0 10px;
  margin: 0;
  font-weight: var(--fontWeightSemiBold);

  font-size: 18px;
  line-height: 1.5;

  @media (--viewportMedium) {
    padding: 96px 0 20px;

    font-size: 24px;
    line-height: 1.5;
  }
}

.testimonial blockquote p {
  margin: 0;
}

.testimonial blockquote:before,
.testimonial blockquote:after {
  content:'';
  position: absolute;
  width: 50px;
  height: 38px;
  opacity: 0.2;
  background-color: currentColor;
  mask: url("data:image/svg+xml,%3Csvg viewBox='0 0 100 77' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='%23000' d='M87.9485 0C88.739 1.2 89.4308 2.4 90.2213 3.5C90.2213 3.6 90.1225 3.9 90.0237 4C88.4426 5.1 86.8615 6.2 85.3792 7.3C81.2288 10.3 77.3749 13.6 73.9163 17.5C70.7541 21.1 67.9871 24.9 66.3072 29.5C65.5167 31.6 65.0226 33.8 64.9238 36C64.9238 36.5 64.825 37 64.825 37.5C64.825 37.7 64.825 37.8 64.9238 38.1C65.2202 37.9 65.3191 37.8 65.5167 37.7C68.3824 35.3 71.7423 33.7 75.4974 33.1C81.4265 32.2 86.9603 33.5 91.8024 37.3C96.1504 40.7 98.8185 45.2 99.7079 50.6C100.795 57.4 98.8185 63.4 94.2729 68.5C92.4941 70.4 90.7154 72.2 88.3438 73.4C86.0709 74.7 83.6005 75.4 81.0312 75.9C78.5607 76.3 75.9914 76.4 73.521 76.3C67.4931 75.9 62.3545 73.5 58.3029 68.9C55.0419 65.3 53.2632 61 52.4726 56.2C51.9785 53.4 51.8797 50.5 51.9785 47.7C52.275 42.2 53.6585 37 56.0301 32C58.5994 26.8 61.9592 22.1 65.912 17.9C70.8529 12.3 76.782 7.5 83.0076 3.3C84.5887 2.2 86.2686 1.1 87.9485 0ZM36.2664 0.1C37.0569 1.3 37.8475 2.6 38.638 3.8C37.6499 4.5 36.6617 5.2 35.6735 5.8C31.3255 8.9 27.1751 12.2 23.5188 16C20.5543 19.1 17.8862 22.5 15.9098 26.4C14.6251 29 13.7358 31.7 13.3405 34.6C13.2417 35.3 13.2417 36.1 13.1429 37C13.4393 36.9 13.5381 36.8 13.637 36.7C16.5027 34.7 19.5661 33.3 23.0247 33C30.2385 32.2 36.464 34.4 41.405 39.9C44.2707 43 45.8518 46.7 46.5435 50.9C46.84 52.8 46.84 54.6 46.6423 56.5C46.5435 57.2 46.5435 58 46.5435 58.7C46.2471 62.3 44.9624 65.5 42.5908 68.3C39.0333 72.6 34.3888 75 28.9538 76C26.4834 76.4 24.0129 76.5 21.5424 76.3C11.6606 75.6 4.2492 69 1.48228 60.4C0.790548 58.1 0.296456 55.7 0.0988185 53.2C0.0988185 52.1 0 50.9 0 49.8C0.0988185 45.8 0.592911 41.9 1.77873 38.1C3.45865 32.7 6.12675 27.7 9.5854 23.2C11.1665 21.1 12.9452 19.1 14.724 17.2C20.8507 10.5 28.1633 5.2 35.6735 0.4L36.2664 0.1Z' /%3E%3C/svg%3E%0A")
    no-repeat 50% 50%;
  mask-size: 100% 100%;

  @media (--viewportMedium) {
    width: 100px;
    height: 76px;
  }
}

.root:global(.negative) .testimonial blockquote:before,
.root:global(.negative) .testimonial blockquote:after {
  background-color: #fff;
}

.testimonial blockquote:before {
  top: 0;
  left: 0;
}
.testimonial blockquote:after {
  bottom: 0;
  right: 0;
  rotate: 180deg;
}

.testimonial figcaption {
  @apply --marketplaceSmallFontStyles;
  height: 50px;
  padding-inline: 50px;
  display: grid;
  place-content: center;

  @media (--viewportMedium) {
    height: 76px;
    padding-inline: 76px;
  }
}

