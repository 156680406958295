@import '../../marketplace.css';

.root {
  position: absolute;
  z-index: var(--zIndexPopup);
  transition: var(--transitionStyleButton);
}

.isClosed {
  visibility: hidden;
  opacity: 0;
  pointer-events: none;
}

.isOpen {
  visibility: visible;
  opacity: 1;
  pointer-events: auto;
}

.content {
  display: flex;
  flex-direction: column;
  margin: 0;
}

