/* Icons */

:root {
}

:global(.icon) {
  display: block;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  background-color: rgba(0, 0, 0, 0.1);
  /* content: attr(class); */
  color: #fff;
}

:global(.icon-touch) {
}
