@import '../../marketplace.css';

.root {
  /* Fixed on top of everything */
  position: fixed;
  bottom: 0;
  left:0;
  right:0;
  z-index: 1000;

  /* Dimensions */
  padding: 20px;

  /* Layout */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  
  height: 30vh;
  text-align: center;

  /* Colors */
  /* background-color: var(--matterColor); */
  background-color: rgba(0,0,0,.9);
  color: var(--matterColorNegative);

  /* Cover Topbar on desktop */
  @media (--viewportMedium) {
    flex-direction: row;
    padding: 30px 20px;
    text-align: left;
    height: 20vh;
  }
}

.message {
  font-size: .875em;
  font-weight: var(--fontWeightSemiBold);
  margin:  0 0 var(--spacing-s);
  @media (--viewportMedium) {
    margin:  0 var(--spacing-s) 0 0;
  }
}

.message > span,
.continueBtn > span {

}

.cookieLink {
  color: var(--matterColorLight);
  border-bottom: 1px solid var(--matterColorNegative);

  &:hover {
    text-decoration: none;
  }
}

.continueBtn {
  /* Font */
  @apply --marketplaceButtonGreenStyles;
  display: block;
  width: 100%;
  font-size: .875em;
  @media (--viewportMedium) {
    display: inline-block;
    width: auto;
  }
}
