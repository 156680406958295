@import '../../marketplace.css';

.root {
  position: relative;
  box-shadow: var(--boxShadowBottomForm);

  @media (--viewportLarge) {
    box-shadow: none;

    /* Clearfix */
    width: 100%;
    overflow: hidden;
  }
}

.textarea {
  /* @apply --marketplaceH4FontStyles; */
  /* border-bottom-width: 0; */
  box-sizing: border-box;
  /* Avoid text going behind the submit button */
  /* padding: 22px 52px 25px 0;

  margin: 0; */
  min-height: 5em;
  /* We need to remove horizontal paddings,
   since textarea uses content-box (autosize library fix). */
  width: calc(100% - 52px);

  /* border-bottom-width: 2px; */
  /* border-bottom-color: var(--attentionColor); */
  /* background-color: transparent; */

  @media (--viewportMedium) {
    margin: 0;
  }
  @media (--viewportLarge) {
    /* padding: 0 0 5px 0; */
    /* margin: 0; */
   box-sizing: border-box;
   width: 100%;
  }
}

.textarea::placeholder { color: var(--c-grey-300);}
.textarea::-webkit-input-placeholder { color: var(--c-grey-300);}

.spinner {
  stroke: var(--matterColorLight);
  width: 18px;
  height: 18px;
  stroke-width: 4px;
}

.fillSuccess {
  fill: var(--successColor);
}

.strokeMatter {
  stroke: var(--matterColor);
}

.submitContainer {
  display: flex;
  flex-direction: row;
}

.errorContainer {
  display: block;
  flex: 1;
  text-align: right;
  padding: 26px 24px 0 0;
}

.error {
  @apply --marketplaceH5FontStyles;
  font-weight: var(--fontWeightMedium);
  color: var(--failColor);
  margin: 0;
}

.submitButton {
  @apply --marketplaceButtonStylesPrimary;
  

  float: right;
  padding: 0 16px;
  min-height: auto;
  min-width: 150px;
  height: 41px;

  display: inline-block;
  margin: 17px 0 0 0;
  width: auto;
}

.sendIcon {
  margin: -3px .5em 0 0;
}

.strokeMatter { stroke: #fff;}
