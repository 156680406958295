@import '../../marketplace.css';

.root {
  display: flex;
  flex-direction: row;
  overflow-x: auto;
  justify-content: center;
  align-items: baseline;
  margin: 0 -20px;
  padding-inline: 10px;
  -webkit-overflow-scrolling: touch;
  text-align: center;

  -ms-overflow-style: none;
  scrollbar-width: none;
}

.root::-webkit-scrollbar {
  display: none;
}

.tab {
}
.tab:first-child {
  margin-inline-start: auto;
}
.tab:last-child {
  margin-inline-end: auto;
}

.tabContent {
  @apply --marketplaceLinkStyles;
  border: 0;
  display: flex;
  /* flex-direction: column; */
  flex-direction: row;
  align-items: baseline;
  justify-content: flex-end;
  height: 100%;
  white-space: nowrap;
  padding: 1em 0.625em; /* 16px  10px */

  /* Font */
  @apply --marketplaceTabNavHorizontalFontStyles;

  color: var(--matterColor);
  transition: var(--transitionStyleButton);

  &:hover,
  &:focus {
    text-decoration: none;
    color: var(--c-yellow-500);
  }

  @media (--viewportMedium) {
    padding: 1em;
  }
}

.tabContent span {
  border-width: 0 0 2px 0;
  border-style: solid;
  border-color: transparent;
}

.button {
  /* Override Chrome's border-radius which is visible when
   border is used as underline */
  border-radius: 0;
  /* Override Chrome's button outline */
  outline: 0;
}
.selectedTabContent {
  pointer-events: none;
}

.selectedTabContent > span:first-child {
  text-decoration: underline;
  text-decoration-thickness: 0.125em;
  text-underline-offset: 0.375em;
  &:hover,
  &:focus {
    color: #000;
    text-decoration: underline;
  }
}

/* .selectedTabContent span {
  border-bottom-color: var(--marketplaceColor);
  color: var(--matterColorDark);
  text-decoration: underline;
} */

.disabled {
  pointer-events: none;
  color: var(--c-grey-300);
  text-decoration: none;
}

/* Dark skin */
.tabContentDarkSkin {
  color: var(--matterColorAnti);

  &:hover {
    color: var(--matterColorLight);
  }
}

.selectedTabContentDarkSkin {
  border-bottom-color: var(--matterColorLight);
  color: var(--matterColorLight);
}

.disabledDarkSkin {
  color: var(--matterColorDark);
  opacity: 0.3;
}

.tabText {
  font-size: 1rem;
  @media (--viewportMedium) {
    font-size: 0.8rem;
  }
}
