@import '../../marketplace.css';
:root {
}

.root {
  display: flex;
  width: 100%;
  flex-shrink: 0;
  gap: 2rem;

  font-size: 14px;

  @media (max-width: 550px) {
    flex-flow: row nowrap;
    margin-inline: -20px;
    padding-inline: 20px;
    width: 100vw;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }

  /*@media (--viewportMedium) {
    flex-wrap: wrap;
  }*/




}

.root::-webkit-scrollbar {
  display: none;
}

.root ul {
  display: flex;
  gap: 1rem;
  margin: 0;
  @media (min-width: 551px) {
    display: block;
    font-size: 0;
  }
}

@media (min-width: 551px) {
  .root ul li {
    display: inline-block;
  }
  .root ul li a {
    display: block;
    margin-right: 14px;
    margin-bottom: 14px;
    font-size: 14px;

  }

}

.currentFilter {
  display: flex;
  white-space: nowrap;
  gap: 6px;
  align-items: baseline;
  &:after {
    content: '×';
  }
  &:hover {
    text-decoration: none;
    color: var(--c-yellow-800);
  }
}
.clearAll {
  white-space: nowrap;
}
