@import '../../marketplace.css';

:root {
  --contentPaddingSmall: 20px;
  --contentGapSmall: 20px;
  --contentPaddingLarge: 60px;
  --contentGapLarge: 36px;

  --titleFontSizeSmall: 18px / 24px;
}

.root {
  @apply --marketplaceContainerStyles;
  padding: var(--spacing-m);
  text-align: center;
  @media (--viewportMedium) {
    padding: var(--spacing-xl);
  }
}

.container {
  position: relative;
  z-index: 2;

  text-align: center;
  display: flex;
  align-items: center;
  flex-direction: column;
  align-items: center;
  margin-inline: auto;
  height: 100%;

  gap: var(--spacing-m);
}

.container p {
  margin: 0;
}
.container p + p {
  margin-block-start: 1em;
}

.root:global(.negative) .button,
.root:global(.negative.yellow) .button {
  background-color: #fff;
  border-color: #fff;
  color: var(--c-yellow-500);
  text-decoration: none;
  &:hover {
    opacity: 0.8;
  }
}

.root:global(.negative) a {
  color: #fff;
  text-decoration: underline;
}

.root:global(.negative) .stepIcon img {
  filter: invert();
}

.root:global(.green) .button {
  background-color: var(--c-green-500);
  border-color: var(--c-green-500);
}

.root:global(.pink.negative) .button {
  background-color: var(--c-white);
  border-color: var(--c-white);
  color: var(--c-pink-500);
}

.root:global(.green.negative) .button {
  background-color: var(--c-white);
  border-color: var(--c-white);
  color: var(--c-green-500);
}

.root:global(.yellow.negative),
.root:global(.pink.negative),
.root:global(.green.negative) {
  color: var(--c-white);
}
.root:global(.yellow.positive) :is(.title, .stepTitle, a:not(.button)) {
  color: var(--c-yellow-500);
}

.root:global(.pink.positive) :is(.title, .stepTitle, a:not(.button)) {
  color: var(--c-pink-500);
}

.root:global(.green.positive) :is(.title, .stepTitle, a:not(.button)) {
  color: var(--c-green-500);
}

.root:global(.negative.yellow) {
  background-color: var(--c-yellow-500);
}
.root:global(.negative.pink) {
  background-color: var(--c-pink-500);
}
.root:global(.negative.green) {
  background-color: var(--c-green-500);
}

.button {
  @apply --marketplaceButtonStyles;
}

.title {
  @apply --marketplaceH2FontStyles;
  margin: 0;
}

.intro {
  max-width: 840px;
  margin-inline: auto;
  font-weight: var(--fontWeightSemiBold);
  @media (--viewportMedium) {
    font-size: 24px;
  }
}

.steps {
  list-style: none;

  max-width: 1120px;
  margin: 0 auto;
  padding: 0;
  display: flex;

  flex-flow: row wrap;
  width: 100%;
  justify-content: center;
  gap: 40px;
}

.steps li {
  width: 100%;
  @media (--viewportMedium) {
    max-width: calc((100% - (1 * 40px)) / 2);
  }

  @media (--viewportLarge) {
    max-width: calc((100% - (2 * 40px)) / 3);
  }
}

.step {
  display: flex;
  flex-flow: column;
  align-items: center;
  font-size: 16px;
}

.stepIcon {
  position: relative;
  width: 52px;
  height: 52px;
}
.stepIcon img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.stepTitle {
  font-weight: var(--fontWeightSemiBold);
  font-size: 18px;

  @media (--viewportMedium) {
    font-size: 21px;
  }
}
