@import '../../marketplace.css';

.root {
  display: flex;
  flex-direction: column;
  color: var(--matterColorDark);
  font-weight: 400;
  position: relative;
  text-align: left;
  
  &:after {
    content: "";
    display: block;
    position: absolute;
    inset: 0;
    z-index: 2;
    box-shadow: inset 0px 0px 0px 1px  rgba(0,0,0,.15);
    pointer-events: none;
  }

  &:hover {
    text-decoration: none;
  }
}

.threeToTwoWrapper {
  display: block;
  width: 100%;
  position: relative;
  transition: var(--transitionStyleButton);
}

.aspectWrapper {
  padding-bottom: 100%; /* 1:1 Aspect Ratio */
  position: relative;
  overflow: hidden;
  background-color: var(--c-grey-500);
}

.rootForImage {
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.info {
  /* Layout */
  display: flex;
  flex-direction: column;
  padding: 10px;
  @media (--viewportMedium) {
    padding: 14px 20px 20px;
  }
}

.details {
  /* Layout */
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  gap: 10px;
  font-size: 0.7rem;
}

.priceValue {
  /* Font */
  @apply --marketplaceCardTitleFontStyles;

  @media (--viewportMedium) {
    text-align: right;
    font-size: 0.9rem;
  }
}

.perUnit {
  /* Font */
  @apply --marketplaceTinyFontStyles;
  color: var(--matterColor);

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.mainInfo {
  display: flex;
  flex-direction: column;
  margin-bottom: var(--spacing-xs);

  @media (--viewportMedium) {
    flex-direction: row;
    justify-content: space-between;
  
  }
}

.title {
  @apply --marketplaceCardTitleFontStyles;
  margin: 0 1em 0 0;
}

.authorInfo {
  /* Font */
  @apply --marketplaceTinyFontStyles;
  color: var(--matterColor);

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.locationInfo {
  display: flex;
  align-items: center;
  gap: 0.25em;
}

.newItem {
  @apply --marketplacePill;
}

.longWord {
  /* fallback option */
  word-break: break-all;
  /* use break-word if available */
  word-break: break-word;
  hyphens: auto;
}

.mapMarkerIcon {
  width: 12px;
  height: 12px;
  fill: #707070; /*make variable */
}

.address {
  margin: 0;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}


.sale {
  display: inline-block;
  position:absolute;
  top: 20px;
  left:0;
  z-index:3;
  font-weight: 600;
  font-size: .45em;
  line-height: 1;
  text-transform: uppercase;
  letter-spacing: .15em;
  color: #ffffff;
  padding: .5em 1.25em .5em .75em;
  background: #ed7273;
  clip-path: polygon(0% 0%, 100% 0%, 85% 50%, 100% 100%, 0% 100%);
  @media (--viewportMedium) {
    font-size: .5em;
  }
}


.sold {
  position: absolute;
  inset: 0;
  margin:auto;
  z-index: 2;
  display: grid;
  place-items: center;
  backdrop-filter: blur(2px) saturate(0);
}

.sold span {
  background-color: #fff;
  padding: .5em 1em;
  font-size: .8125em;
  line-height: 1;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: .15em;

}

.heart {
  position: absolute;
  top: 0;
  right: 0;
  z-index:4;
}

.oldPriceValue {
  color: #aaaaaa;
  text-decoration: line-through;
  margin-right: 5px;
}

.aspectRatioWrapper {
  transition: var(--transitionStyleButton);
  background: var(--matterColorNegative); /* Loading BG color */
  border-radius: 4px;

  @media (--viewportMedium) {
    &:hover {
      transform: scale(1.02);
      box-shadow: var(--boxShadowListingCard);
    }
  }
}
