@import '../../marketplace.css';

:root {
}

.root {
  @apply --marketplaceContainerStyles;
  position: relative;
  margin-block: var(--spacing-s);
  @media (--viewportMedium) {
    margin-block: var(--spacing-m);
    padding-inline: var(--spacing-m);
  }
  @media (--viewportLarge) {
    padding-inline: var(--spacing-l);
  }
  @media (--viewportXLarge) {
    padding-inline: var(--ContainerHorizontalPaddingMedium);
  }

}

.content {
  display: grid;
  gap: var(--spacing-s);

  @media (--viewportSmall) {
    grid-template-columns: repeat( auto-fit, minmax(160px, 1fr) );;

  }
  @media (--viewportMedium) {
    gap: var(--spacing-m);
  }
}

.content > :last-child {
  @media (--viewportMobileOnly) {
    grid-column: span 2;
  }
}
    



