@import '../../marketplace.css';

.root {
}

.container {
  @apply --marketplaceContainerNarrowStyles;
  margin-block: var(--spacing-s);

  @media (--viewportMedium) {
    margin-block: var(--spacing-xl);
  }
}

.intro {
  font-size: 1.125em;
  font-weight: var(--fontWeightSemiBold);
}

.container figure {
  margin: 1.5em 0;
  padding: 0;
}

.container figcaption {
  font-size: .7em;
  padding: 1em 0;
}

.container figure img {
  width: 100%;
  height: auto;
}
