@import '../../marketplace.css';

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */
}

.root {
  width: 100%;
  z-index: var(--zIndexTopbar);
  background-color: var(--c-white);

  @media (--viewportMedium) {
    padding: 20px;
    margin: 0 auto;
    max-width: var(--ContainerWidthLarge);
  }
}

.root[aria-expanded="true"]  {
  position: fixed;
  top: 0;
  height: 100%;
}

body:has(.root[aria-expanded="true"]) {
  position: fixed;
}

.container {
  display: flex;
  position: relative;
  justify-content: space-between;
  align-items: baseline;
}

[aria-expanded="true"] .container {
  padding: 0;
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: calc(100% - 56px) 56px;
  grid-template-rows: 80px auto min-content;
  grid-template-areas: 'search toggle' 'middle middle' 'bottom bottom';

  @supports (height: 100dvh) {
    height: 100dvh;
  }
}

/* logo */
.logo {
  width: 64px;
  height: 58px;
  flex-shrink: 0;
  margin: 10px 20px;
  @media (--viewportMedium) {
    width: 88px;
    height: 80px;
    margin: 0 20px 0 0;
  }

  @media (--viewportLarge) {
    width: 88px;
    height: 80px;
    margin: 0 20px 0 0;
  }
  @media (--viewportLarger) {
    width: 110px;
    height: 100px;
    margin-right: 80px;
  }
}

.logoMark {
  height: 100%;
  width: 100%;
  object-fit: contain;
  object-position: left center;
}

[aria-expanded="true"] .logo {
  display: none;
}

:global(.has-navigation-opened),
[aria-expanded="true"] body {
  overflow: hidden;
}

/* ================ Modal ================ */

.modalContainer {
  @apply --marketplaceModalInMobileBaseStyles;
  padding: 0;

  @media (--viewportMedium) {
    flex-basis: 576px;
  }
}

.missingInformationModal {
  @apply --marketplaceModalBaseStyles;
  padding-top: 70px;
}

/* Title of the modal */
.modalTitle {
  @apply --marketplaceModalTitleStyles;
}

/* Paragraph for the Modal */
.modalMessage {
  @apply --marketplaceModalParagraphStyles;
}

/* Make the email pop */
.email {
  @apply --marketplaceModalHighlightEmail;
}

.helperLink {
  @apply --marketplaceModalHelperLink;
}

.helperText {
  @apply --marketplaceModalHelperText;
}

.error {
  @apply --marketplaceModalErrorStyles;
}

.bottomWrapper {
  @apply --marketplaceModalBottomWrapper;

  /* Align the helper links to the left since there isn't a Primary Button anymore */
  text-align: left;
}

.modalIcon {
  @apply --marketplaceModalIconStyles;
}

.reminderModalLinkButton {
  @apply --marketplaceButtonStylesPrimary;
}

.genericError {
  position: fixed;
  top: calc(var(--topbarHeight) + 15px);

  /* Place enough to the right to hide the error */
  right: -355px;
  opacity: 0;

  max-width: 340px;
  margin: 0 15px;

  /* Animate error from the right */
  transition: all ease-out 0.2s;
  transition-delay: 1s;

  /* Bleed the bg color from the bottom */
  padding-bottom: 4px;
  background-color: var(--marketplaceColor);
  border-radius: 4px;

  /* Place on top of everything */
  z-index: var(--zIndexGenericError);

  @media (--viewportMedium) {
    top: calc(var(--topbarHeightDesktop) + 15px);
  }
}

.genericErrorVisible {
  right: 0;
  opacity: 1;
}

.genericErrorContent {
  padding: 16px 22px;
  border-radius: 4px;
  background-color: var(--marketplaceColorLight);
  border-radius: 4px;
}

.genericErrorText {
  @apply --marketplaceH5FontStyles;
  font-weight: var(--fontWeightSemiBold);
  margin: 0;
  color: var(--matterColorLight);

  @media (--viewportMedium) {
    margin: 0;
  }
}
