@import '../../marketplace.css';

:root {
}

.root {
  @apply --marketplaceContainerStyles;
  position: relative;
  display: flex;
  flex-flow: column nowrap;
  
  padding: var(--spacing-l);
  margin-block: var(--spacing-s);

  @media (--viewportMedium) {
    flex-flow: row nowrap;
    padding: var(--spacing-l);
    margin-block: var(--spacing-s);
  }
  @media (--viewportLarge) {
    padding: calc( var(--spacing-l) * 2 ) var(--spacing-l);
  }

}

.image {
  width: 100%;
  position: absolute;
  inset: 0;
  
}

.image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  aspect-ratio: 1;
}

.container {
  width: 100%;  
  position: relative;
  background-color: #FFF;

  margin-inline: auto;
  display: flex;
  flex-flow: column;
  justify-content: center;
  text-align: center;
  padding:  0 var(--spacing-s);

  @media (--viewportMedium) {
    padding: var(--contentPaddingMedium);
    max-width: calc(var(--ContainerWidthLarge) / 2);

  }
  @media (--viewportLarge) {
    padding: var(--spacing-l);
  }
  
  @media (--viewportMobileOnly) {
    aspect-ratio: 1;
  }

}

.title {
  @apply --marketplaceH2FontStyles;
  margin-block: var(--spacing-s) 0;
}

.footer {
  margin-block: 0 var(--spacing-s);
}

.button {
  @apply --marketplaceButtonStyles;
}

.root:global(.yellow) .button  {
  background-color: var(--c-yellow-500);
  border-color: var(--c-yellow-500);
}

.root:global(.pink) .button {
  background-color: var(--c-pink-500);
  border-color: var(--c-pink-500);
}

.root:global(.green) .button {
  background-color: var(--c-green-500);
  border-color: var(--c-green-500);
}
