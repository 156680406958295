@import '../../marketplace.css';

.root {
  /* Font */
  font-weight: var(--fontWeightBold);
  font-size: .625em;
  line-height: 1.6;
  color: var(--c-white);
  text-align: center;

  background-color: var(--c-pink-500);

  /* Layout of a red dot */
  display: inline-block;
  width: auto;
  min-width: 2em;
  border-radius: 1.5em;
  padding-inline: .125em;
  padding-top: .125em;
  margin-inline-start: .5em;
  text-align: center;

}

