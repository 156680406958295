@import '../../marketplace.css';

.root {
  margin: 20px 0;
  @media (--viewportMedium){
    margin: 40px 0
  }
}

.select {
  color: var(--matterColorAnti);
  border-color: var(--attentionColor);
  padding-right: 20px;

  & > option {
    color: var(--matterColor);
  }
  & > option:disabled {
    color: var(--matterColorAnti);
  }
}

.selectSuccess {
  color: var(--matterColor);
  border-color: var(--successColor);
}

.selectError {
  border-color: var(--failColor);
}
