@import '../../octopusClub.css';

:root {
  --SectionHeaderStyles {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  --SectionHeaderH1Styles {
    font-size: 48px;
    line-height: 54px;
  }
}

.root {
  background-color: var(--matterColorLight);
}
.content {
  @apply --marketplaceContainerNarrowStyles;
  margin-bottom: var(--spacing-m);
}

.contentModal {
  @media (--viewportMedium) {
    max-width: 565px;
    margin: 55px auto 56px auto;
  }
}

.headingContainer {
  @apply --SectionHeaderStyles;
}

.heading {
  @apply --SectionHeaderH1Styles;
}

.profileLink {
  font-size: 0.8rem;
  /* @apply --marketplaceButtonStylesSecondary; */
}

/* ================ Hide Top bar in screens smaller than 768px  ================ */

.hideOnMobile {
  display: none;

  @media (--viewportMedium) {
    display: block;
  }
}

/* ================ Close icon ================ */

.verifyClose {
  display: flex;
  justify-content: flex-end;
  color: #000000;
  cursor: pointer;
}

.closeText {
  @apply --marketplaceModalCloseText;
}

.closeIcon {
  @apply --marketplaceModalCloseIcon;
}

/* ================ Typography ================ */

/* Title of the modal */
.modalTitle {
  @apply --marketplaceModalTitleStyles;
}

/* Paragraph for the Modal */
.modalMessage {
  @apply --marketplaceModalParagraphStyles;
}

/* Make the email pop */
.email {
  @apply --marketplaceModalHighlightEmail;
}

/* Helper links */
.modalHelperLink {
  @apply --marketplaceLinkStyles;
  @apply --marketplaceModalHelperLink;
}

/* Helper texts for the links, not the actual links */
.modalHelperText {
  @apply --marketplaceModalHelperText;
}

.modalIcon {
  @apply --marketplaceModalIconStyles;

  /* Align the icon and rest of the content in the modal. Sign & Login doesn't use an icon, maybe we should add one? */
  margin-top: 47px;

  @media (--viewportMedium) {
    margin-top: 0;
  }
}

/* ================ Errors ================ */

.error {
  @apply --marketplaceModalErrorStyles;
}

.typeDescription {
  @apply --marketplaceBodyFontStyles;
  color: var(--marketplaceColor);
}

.productTypeModalTitle {
  @apply --marketplaceModalTitleStyles;
  margin-bottom: 32px;
}

.productTypeModalButton {
  margin-bottom: 16px;
}
