@import './fonts.css';
@import './octopusClubFonts.css';

/* ================ Custom properties aka variables ================ */

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */

  /**
   * Font variables are separated to different file so that the amount of font styling rules
   * doesn't overflow this one.
   * see ./octopusClubFonts.css
   * Usage example: .root { @apply --marketplaceH1FontStyles; }
   */

  /* Full screen Background image located in root-folder/src/assets */
  --backgroundImage: {
    /* Gradient direction and overlaying the black color on top of the image for better readability */
    background: linear-gradient(-45deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.6)),
      url('../../assets/plastic-toys-on-floor.jpg');

    /* Add loading color for the div */
    background-color: var(--matterColor);

    /* Cover the whole screen with the background image */
    background-size: cover;

    /* Align the image within the container */
    background-position: center center;

    @media (--viewportLarge) {
      border-radius: 40px;
      border: solid 36px var(--matterColorBright);
    }
  }


  /* ================ Spacing ================ */

  --ContainerWidthLarge: 1440px;
  --ContainerHorizontalPadding: 20px;

  --spacing-s: 20px;
  --spacing-m: 40px;
  --ContainerHorizontalPaddingLarge: 60px;

  @media (--viewportSmall) {
    --ContainerHorizontalPadding: 20px;
  }

  @media (--viewportMedium) {
    --ContainerHorizontalPadding: 30px;
  }

  @media (--viewportLarge) {
    --ContainerHorizontalPadding: 30px;
  }

  @media (--viewportLarger) {
    --ContainerHorizontalPadding: 40px;
  }


  /* ================ Colors ================ */

  --marketplaceColor: #DEAD2C;
  --marketplaceColorLight: #e9c872;
  --marketplaceColorDark: #C08F1F;
  
  --c-black: #000;
  --c-white: #FFF;

  --c-grey-100: hsl(231,5%,87%);
  --c-grey-200: hsl(231,5%,77%);
  --c-grey-300: hsl(231,5%,67%);
  --c-grey-400: hsl(231,5%,57%);
  --c-grey-500: hsl(231,5%,47%);
  --c-grey-600: hsl(231,5%,37%);
  --c-grey-700: hsl(231,5%,27%);
  --c-grey-800: hsl(231,5%,17%);
  --c-grey-900: hsl(231,5%,7%);

  --c-yellow-h: 43;
  --c-yellow-s: 73%;
  --c-yellow-l: 52%;
  
  --c-yellow: hsl(var(--c-yellow-h), var(--c-yellow-s), var(--c-yellow-l));
  
  /* --c-yellow-050: hsl(var(--c-yellow-h), var(--c-yellow-s), calc(var(--c-yellow-l)  * 1.9  )); */
  --c-yellow-050: #FDFAF2;
  --c-yellow-100: hsl(var(--c-yellow-h), var(--c-yellow-s), calc(var(--c-yellow-l)  * 1.8  ));
  --c-yellow-150: hsl(var(--c-yellow-h), var(--c-yellow-s), calc(var(--c-yellow-l)  * 1.7  ));
  --c-yellow-200: hsl(var(--c-yellow-h), var(--c-yellow-s), calc(var(--c-yellow-l)  * 1.6  ));
  --c-yellow-300: hsl(var(--c-yellow-h), var(--c-yellow-s), calc(var(--c-yellow-l)  * 1.4  ));
  --c-yellow-400: hsl(var(--c-yellow-h), var(--c-yellow-s), calc(var(--c-yellow-l)  * 1.2  ));
  --c-yellow-500: var(--c-yellow);
  --c-yellow-600: hsl(var(--c-yellow-h), var(--c-yellow-s), calc(var(--c-yellow-l)  * .9  ));
  --c-yellow-700: hsl(var(--c-yellow-h), var(--c-yellow-s), calc(var(--c-yellow-l)  * .8 ));
  --c-yellow-800: hsl(var(--c-yellow-h), var(--c-yellow-s), calc(var(--c-yellow-l)  * .7  ));
  --c-yellow-900: hsl(var(--c-yellow-h), var(--c-yellow-s), calc(var(--c-yellow-l)  * .6 ));

  --c-pink-h: 359;
  --c-pink-s: 78%;
  --c-pink-l: 68%;
  --c-pink: hsl(var(--c-pink-h), var(--c-pink-s), var(--c-pink-l));

  --c-pink-100: hsl(var(--c-pink-h), var(--c-pink-s), calc(var(--c-pink-l) * 1.4 ));
  --c-pink-200: hsl(var(--c-pink-h), var(--c-pink-s), calc(var(--c-pink-l) * 1.3 ));
  --c-pink-300: hsl(var(--c-pink-h), var(--c-pink-s), calc(var(--c-pink-l) * 1.2 ));
  --c-pink-400: hsl(var(--c-pink-h), var(--c-pink-s), calc(var(--c-pink-l) * 1.1 ));
  --c-pink-500: var(--c-pink);
  --c-pink-600: hsl(var(--c-pink-h), calc(var(--c-pink-s) * .95), calc(var(--c-pink-l) * .9 ));
  --c-pink-700: hsl(var(--c-pink-h), calc(var(--c-pink-s) * .9), calc(var(--c-pink-l) * .825 ));
  --c-pink-800: hsl(var(--c-pink-h), calc(var(--c-pink-s) * .85), calc(var(--c-pink-l) * .75 ));
  --c-pink-900: hsl(var(--c-pink-h), calc(var(--c-pink-s) * .8), calc(var(--c-pink-l) * .625 ));

  --c-green-h: 141;
  --c-green-s: 39%;
  --c-green-l: 51%;
  --c-green: hsl(var(--c-green-h), var(--c-green-s), var(--c-green-l));

  --c-green-100: hsl(var(--c-green-h), var(--c-green-s), calc(var(--c-green-l) * 1.8 ));
  --c-green-200: hsl(var(--c-green-h), var(--c-green-s), calc(var(--c-green-l) * 1.6 ));
  --c-green-300: hsl(var(--c-green-h), var(--c-green-s), calc(var(--c-green-l) * 1.4 ));
  --c-green-400: hsl(var(--c-green-h), var(--c-green-s), calc(var(--c-green-l) * 1.2 ));
  --c-green-500: var(--c-green);
  --c-green-600: hsl(var(--c-green-h), var(--c-green-s), calc(var(--c-green-l) * .9 ));
  --c-green-700: hsl(var(--c-green-h), var(--c-green-s), calc(var(--c-green-l) * .8 ));
  --c-green-800: hsl(var(--c-green-h), var(--c-green-s), calc(var(--c-green-l) * .7 ));
  --c-green-900: hsl(var(--c-green-h), var(--c-green-s), calc(var(--c-green-l) * .6 ));

  --c-blue-h: 197;
  --c-blue-s: 56%;
  --c-blue-l: 56%;
  --c-blue: hsl(var(--c-blue-h), var(--c-blue-s), var(--c-blue-l));
  --c-blue-500: var(--c-blue);

  --c-blue-100: hsl(var(--c-blue-h), var(--c-blue-s), calc(var(--c-blue-l) * 1.8 ));
  --c-blue-200: hsl(var(--c-blue-h), var(--c-blue-s), calc(var(--c-blue-l) * 1.6 ));
  --c-blue-300: hsl(var(--c-blue-h), var(--c-blue-s), calc(var(--c-blue-l) * 1.4 ));
  --c-blue-400: hsl(var(--c-blue-h), var(--c-blue-s), calc(var(--c-blue-l) * 1.2 ));
  --c-blue-500: var(--c-blue);
  --c-blue-600: hsl(var(--c-blue-h), var(--c-blue-s), calc(var(--c-blue-l) * .9 ));
  --c-blue-700: hsl(var(--c-blue-h), var(--c-blue-s), calc(var(--c-blue-l) * .8 ));
  --c-blue-800: hsl(var(--c-blue-h), var(--c-blue-s), calc(var(--c-blue-l) * .7 ));
  --c-blue-900: hsl(var(--c-blue-h), var(--c-blue-s), calc(var(--c-blue-l) * .6 ));

  /* Used with inline CSS SVGs */
  --marketplaceColorEncoded: %23c0392b;

  --successColor: var(--c-green-500);
  --successColorDark: var(--c-green-700);;
  --successColorLight: var(--c-green-100);;
  --failColor: var(--c-pink-600);
  --failColorLight: var(--c-pink-100);
  --attentionColor:  var(--c-grey-100); /*var(--c-yellow-500);*/
  --attentionColorLight: var(--c-pink-100);
  --bannedColorLight: var(--c-pink-100);
  --bannedColorDark: var(--c-pink-600);

  --matterColorDark: #000000;
  --matterColor: #000000;
  --matterColorAnti: var(--c-grey-600);
  --matterColorNegative: #e7e7e7;
  --matterColorBright: #fcfcfc;
  --matterColorLight: #ffffff;

  --matterColorLightTransparent: rgba(255, 255, 255, 0.65);

  /* ================ Spacing unites ================ */

  /* Multiples of mobile and desktop spacing units should be used with margins and paddings. */
  --spacingUnit: 6px;
  --spacingUnitDesktop: 8px;

  /* Shadows */
  --boxShadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  --boxShadowTop: 0 -2px 4px 0 rgba(0, 0, 0, 0.1);
  --boxShadowLight: 0 2px 4px 0 rgba(0, 0, 0, 0.05);
  --boxShadowPopup: 0 8px 16px 0 rgba(0, 0, 0, 0.3);
  --boxShadowPopupLight: 0 3px 6px 0 rgba(0, 0, 0, 0.2);
  --boxShadowBreakdown: 0 3px 6px 0 rgba(0, 0, 0, 0.2);
  --boxShadowButton: none; /*0 4px 8px 0 rgba(0, 0, 0, 0.1);*/
  --boxShadowListingCard: 0 0 50px 0 rgba(0, 0, 0, 0.1);
  --boxShadowNotFoundPageSearch: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  --boxShadowSectionLocationHover: 0 10px 30px 0 rgba(0, 0, 0, 0.1);
  --boxShadowBottomForm: 0 -2px 4px 0 rgba(0, 0, 0, 0.05);
  --boxShadowFilterButton: 0 4px 16px 0 rgba(0, 0, 0, 0.2);

  /* ================ Z-index base levels ================ */

  /* topbar is positioned slightly above content */
  --zIndexTopbar: 10;
  /* small popups on UI should use z-indexes above 50 */
  --zIndexPopup: 50;
  /* modals and UI overlays should use z-indexes above 100 */
  --zIndexModal: 100;
  /* generic error message should overlay even modals */
  --zIndexGenericError: 200;

  /* ================ Border radius ================ */

  --borderRadius: 0;
  --borderRadiusMobileSearch: 0;

  /* ================ Transition styles ================ */

  --transitionStyle: ease-in 0.2s;
  --transitionStyleButton: ease-in-out 0.1s;

  /* ================ Topbar heights ================ */

  --topbarHeight: 80px;
  --topbarHeightDesktop: 140px;

  /* ================ Modal default padding ================ */

  --modalPadding: 24px 24px 48px 24px;
  --modalPaddingMedium: 55px 60px 55px 60px;

  /* ================ LocationAutocompleteInput bottom attribution padding ================ */

  /* Google Maps needs 72px bottom padding to accommodate logo, Mapbox doesn't have one */
  --locationAutocompleteBottomPadding: 8px;

  /* ================ Clearfix solution ================ */

  --clearfix {
    &::after {
      content: '';
      display: table;
      clear: both;
    }
  }

  /* ================ Links ================ */

  --marketplaceLinkStyles: {
    /* Position and dimensions */
    display: inline;
    margin: 0;
    padding: 0;
    font-weight: var(--fontWeightMedium);
    /* Borders */
    border: none;

    /* Colors */
    color: var(--marketplaceColorDark);

    /* Text size should be inherited */
    text-decoration: none;

    &:hover {
      text-decoration: underline;
      cursor: pointer;
    }
  }

  /* ================ Buttons ================ */

  --marketplaceButtonFontStyles: {
    font-family: var(--fontFamily);
    font-weight: var(--fontWeightBold);
    font-size: 14px;
    line-height: 1;
    letter-spacing: 0.14em;
    text-transform: uppercase;
    @media (--viewportMedium) {
      font-size: 16px;

    }
  }
    
  --marketplaceButtonStyles {
    @apply --marketplaceButtonFontStyles;

    /* Dimensions */
    display: inline-block;
    margin: 0;
    /* width: 100%; */
    padding: 10px 16px;

    @media (--viewportMedium) {
      width: auto;
      padding: 12px 18px;
    }

    /* Borders */
    border-width: 2px;
    border-style: solid;
    border-color: var(--marketplaceColor);
    border-radius: var(--borderRadius);

    /* Colors */
    background-color: var(--marketplaceColor);
    color: var(--matterColorLight);

    /* Text */
    text-align: center;
    text-decoration: none;
    white-space: nowrap;
    
    /* Effects */
    transition: all var(--transitionStyleButton);
    cursor: pointer;

    &:hover,
    &:focus {
      outline: none;
      background-color: var(--marketplaceColorDark);
      border-color: var(--marketplaceColorDark);
      transition: none;
      text-decoration: none;
    }
    &:disabled,
    &[disabled] {
      background-color: var(--c-grey-200);
      border-color: var(--c-grey-200);
      color: var(--c-white);
    }
  }

  --marketplaceButtonStylesPrimary {
    @apply --marketplaceButtonStyles;

    background-color: var(--c-green-500);
    color: var(--c-white);
    border-color: var(--c-green-500);

    &:hover,
    &:focus {
      background-color: var(--c-green-700);
      border-color: var(--c-green-700);
      transition: none;
    }
    &:disabled,
    &[disabled] {
      background-color: var(--c-grey-200);
      border-color: var(--c-grey-200);
      color: var(--c-white);
    }
  }

  --marketplaceButtonStylesPrimaryGreen {
    @apply --marketplaceButtonStylesPrimary;
    border-color: var(--c-green-500);
    background-color: var(--c-green-500);
  }

  --marketplaceButtonStylesSecondary {
    @apply --marketplaceButtonStyles;

    background-color: transparent;
    color: var(--marketplaceColor);

    /* Padding is only for <a> elements where button styles are applied,
   buttons elements should have zero padding */

    border-width: 2px;
    border-style: solid;
    border-color: currentColor;
        
    &:hover,
    &:focus {
      background-color: transparent;
      color: var(--marketplaceColorDark);
      transition: none;
    }
    &:disabled,
    &[disabled] {
      background-color: var(--c-grey-200);
      border-color: var(--c-grey-200);
      color: var(--c-white);
    }

  }

  --marketplaceButtonStylesGreenSecondary {
    @apply --marketplaceButtonStylesSecondary;
    color: var(--c-green-500);
  }

  --marketplaceButtonYellowStyles {
    @apply --marketplaceButtonStyles;

    background-color: var(--c-yellow-500);
    border-color: var(--c-yellow-500);

    &:hover,
    &:focus {
      background-color: var(--c-yellow-600);
      border-color: var(--c-yellow-600);
      }
  }

  --marketplaceButtonGreenStyles {
    @apply --marketplaceButtonStyles;

    background-color: var(--c-green-500);
    border-color: var(--c-green-500);

    &:hover,
    &:focus {
      background-color: var(--c-green-600);
      border-color: var(--c-green-600);
      }
  }

  --marketplaceButtonPinkStyles {
    @apply --marketplaceButtonFontStyles;

    background-color: var(--c-pink-500);
    border-color: var(--c-pink-500);

    &:hover,
    &:focus {
      background-color: var(--c-pink-600);
      border-color: var(--c-pink-600);
      }
  }

  --marketplaceButtonBlueStyles {
    @apply --marketplaceButtonFontStyles;

    background-color: var(--c-blue-500);
    border-color: var(--c-blue-500);

    &:hover,
    &:focus {
      background-color: var(--c-blue-600);
      border-color: var(--c-blue-600);
      }
  }
  
  --marketplaceButtonSmallDesktopPadding: 9px 16px 9px 16px;

  /* ================ Inputs ================ */

  --marketplaceInputStyles: {
    /* Dimensions */
    display: block;
    width: 100%;
    margin: 0;
    padding: 10px 16px;
    font-size: 16px;
    line-height: 1.5;
    
    /* Borders */
    border: 1px var(--c-grey-100) solid;

    &::placeholder {
      color: var(--matterColorAnti);
    }

    /* Effects */

    &:hover,
    &:focus {

    }

  }

  --marketplaceSelectStyles: {
    /* Dimensions */
    display: block;
    width: 100%;
    margin: 0;
    padding: 10px 16px;
    font-size: 16px;

    /* Unset user agent styles */
    appearance: none;

    /* Borders */
    border: 1px var(--c-grey-100) solid;
    border-radius: 0;

    /* Background */
    background-image:url("data:image/svg+xml,%3Csvg width='14' height='9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.314 1 6.657 6.657 1 1' stroke='%23000000' stroke-width='2'/%3E%3C/svg%3E");
    background-size: 14px 9px;
    background-position: calc(100% - 16px) 50%;

    /* Effects */

    cursor: pointer;
    transition: border-bottom-color var(--transitionStyle);

    &:hover,
    &:focus {

    }

    @media (--viewportMedium) {}
  }

  /* ================ Modals ================ */

  --marketplaceModalRootStyles {
    /* Page is using flex: AuthenticationPage's .root takes all available space */
    flex-grow: 1;

    /* AuthenticationPage's root uses flexbox */
    display: flex;
    
    @media (--viewportMedium) {
      justify-content: center;
      align-items: flex-start;
      place-items: center;
    }
  }

  --marketplaceModalFormRootStyles {
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: space-between;
    height: 100%;
  }

  --marketplaceModalBaseStyles {
    flex-grow: 1;

    /* Create context so the close button can be positioned within it */
    position: relative;

    /* Display and dimensions */
    display: flex;
    flex-direction: column;
    padding: var(--modalPadding);

    background-color: var(--matterColorLight);
    border-radius: var(--borderRadius);
    border-bottom: none;

    @media (--viewportMedium) {
      flex-basis: calc(var(--ContainerWidthLarge) / 2 );
      flex-grow: 0;
      /* min-height: 568px; */
      padding: var(--modalPaddingMedium);
      /* margin-top: 12.5vh;
      margin-bottom: 12.5vh; */
      /* border-bottom: 8px solid var(--marketplaceColor); */
    }
  }

  --marketplaceModalInMobileBaseStyles {
    flex-grow: 1;

    /* Create context so the close button can be positioned within it */
    position: relative;

    /* Display and dimensions */
    display: flex;
    flex-direction: column;
    height: 100vh;
    padding: 0;

    background-color: var(--matterColorLight);
    border-radius: var(--borderRadius);

    @media (--viewportMedium) {
      flex-basis: 480px;
      flex-grow: 0;
      height: 100%;
      padding: 0;
      background-color: transparent;
      border-radius: 0;
    }
  }

  /* Modal title */

  --marketplaceModalTitleStyles {
    font-weight: var(--fontWeightSemiBold);
    font-size: 1em;
    line-height: 1.5;
    margin: 0;
    font-weight: var(--fontWeightBold);

    @media (--viewportMedium) {
      font-weight: var(--fontWeightSemiBold);
      /* line-height: 40px;
      letter-spacing: -0.9px; */
    }
  }

  /* Modal message */

  --marketplaceModalParagraphStyles {
    margin: 18px 0;

    @media (--viewportMedium) {
      margin: 24px 0;
    }
  }

  /* Bottom wrapper for the modal */

  --marketplaceModalBottomWrapper {
    /* margin-top: 40px; */
    align-self: stretch;
  }

  /* Adjust the bottomWrapper's margin to match modals without the helper text */
  --marketplaceModalBottomWrapperText {
    /* margin-top: -24px;
    padding-bottom: 8px;

    @media (--viewportMedium) {
      padding-bottom: 0;
    } */
  }

  --marketplaceModalHelperText {
    /* @apply --marketplaceTinyFontStyles; */
    font-size: 16px;
    line-height: 1.5;
    margin: 0;

    @media (--viewportMedium) {
      margin: 0;
    }
  }

  --marketplaceModalHelperLink {

  }

  /* Modal Close icon */

  --marketplaceModalCloseStyles {
    /* Position inside modal */
    position: fixed;
    top: 0;
    right: 0;

    /* Some content (like map) is positioning itself on top of close button without z-index handling */
    z-index: calc(var(--zIndexModal) + 1);

    /* Safari didn't know how to baseline-align svg icon and text */
    display: flex;
    align-items: center;
    width: auto;

    /* Sizing (touch area) */
    padding: 20px;
    margin: 0;
    border: 0;

    @media (--viewportMedium) {
      padding: 30px 30px;
      position: absolute;
    }

    /* Colors */
    background-color: transparent;
    color: var(--matterColor);

    cursor: pointer;

    &:enabled:hover {
      background-color: transparent;
      color: var(--matterColorDark);
      box-shadow: none;
      text-decoration: none;
    }
    &:enabled:active {
      background-color: transparent;
      color: var(--matterColorDark);
    }
    &:disabled {
      background-color: transparent;
    }
  }

  --marketplaceModalIconStyles {
    height: 48px;
    margin-bottom: 12px;

    @media (--viewportMedium) {
      height: 64px;
      margin-bottom: 23px;
    }
  }

  --marketplaceModalCloseText {
    /* Font */
    @apply --marketplaceH6FontStyles;
    margin: -2.5px 0 0 0;

    @media (--viewportMedium) {
      margin-top: -1px;
    }
  }

  --marketplaceModalCloseIcon {
    display: inline-block;
    margin-left: 8px;
    padding: 2px 0 4px;
    box-sizing: content-box;
  }

  --marketplaceModalErrorStyles {
    @apply --marketplaceH4FontStyles;
    margin-top: 24px;
    color: var(--failColor);
  }

  --marketplaceModalHighlightEmail {
    font-weight: var(--fontWeightBold);
  }

  --marketplaceModalPasswordMargins {
    /* Leave space between the input and the button below when the
    viewport height is small */
    margin-top: 24px;

    @media (--viewportMedium) {
      margin-top: 32px;
    }
  }


  /* ================ Pills ================ */

  --marketplacePillFontStyles: {
    font-family: var(--fontFamily);
    font-weight: var(--fontWeightSemiBold);
    font-size: .7rem;
    line-height: 1;

  }
  --marketplacePillStyles {
    @apply --marketplacePillFontStyles;

    /* Dimensions */
    display: inline-block;
    padding: .25em 1em;

    /* Borders */
    border: 1px currentColor solid;
    border-radius: 1em;

    /* Colors */
    color: var(--marketplaceColorDark);

    /* Text */
    text-align: center;
    text-decoration: none;
  }

  /* ================ Grid ================ */

  --gridStyle {

    display: grid;
    gap: 20px;  

    @media (--viewportXSmall) {
      grid-template-columns: repeat(2, 1fr);
    }

    @media (--viewportMedium) {
      gap: 30px;
      grid-template-columns: repeat(3, 1fr);
    }

    @media (--viewportLarge) {
      margin: 40px auto;
      gap: 30px;
      grid-template-columns: repeat(4, 1fr);
    }
    @media (--viewportLarger) {
      gap: 40px;
    }
  }

  /* ================ icons ================ */

  --icon-arrow: url("data:image/svg+xml,%3Csvg width='12' height='12' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4 1L9 6L4 11' stroke='%23000' stroke-width='2' stroke-linecap='round'/%3E%3C/svg%3E%0A");
  --icon-inbox: url("data:image/svg+xml,%3Csvg width='22' height='24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M21.523 19.391c0-.013 0-.025-.002-.036a5.8 5.8 0 0 0-.105-.796c-.187-.91-.717-1.766-1.621-2.618-1.196-1.127-1.183-3.155-1.183-3.175v-3.08a7.843 7.843 0 0 0-5.387-7.457.09.09 0 0 1-.061-.076 2.417 2.417 0 0 0-4.805 0 .09.09 0 0 1-.061.076A7.847 7.847 0 0 0 2.91 9.685v3.084a5.695 5.695 0 0 1-.143 1.17c-.133.591-.421 1.42-1.04 2.002-.903.852-1.433 1.708-1.62 2.618A5.867 5.867 0 0 0 0 19.39a1.494 1.494 0 0 0 1.492 1.492h5.54a.09.09 0 0 1 .088.074 3.7 3.7 0 0 0 7.282 0 .09.09 0 0 1 .089-.074h5.54a1.493 1.493 0 0 0 1.492-1.492Zm-8.319 1.607a2.54 2.54 0 0 1-4.885 0 .09.09 0 0 1 .086-.115h4.713a.09.09 0 0 1 .086.115Zm6.826-1.275H1.492a.332.332 0 0 1-.33-.323c.014-.204.041-.407.082-.608.14-.677.546-1.314 1.28-2.006.813-.767 1.19-1.8 1.362-2.531.118-.49.18-.99.185-1.494V9.685a6.687 6.687 0 0 1 5.015-6.479.582.582 0 0 0 .431-.633 1.256 1.256 0 1 1 2.489 0 .582.582 0 0 0 .43.633 6.687 6.687 0 0 1 5.016 6.48v3.062c-.002.105-.026 2.555 1.547 4.038.733.69 1.14 1.327 1.279 2.003v.003a4.65 4.65 0 0 1 .083.62.332.332 0 0 1-.33.31Z' fill='%231D1D1B'/%3E%3C/svg%3E");
  --icon-search: url("data:image/svg+xml,%3Csvg width='25' height='24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9.863 15.058a5.6 5.6 0 0 1-5.587-5.845.584.584 0 1 0-1.167-.052 6.768 6.768 0 0 0 6.754 7.064.584.584 0 0 0 0-1.167Z' fill='%231D1D1B'/%3E%3Cpath d='m23.764 20.457-5.891-5.891a9.497 9.497 0 1 0-2.911 2.93l5.881 5.881a2.11 2.11 0 0 0 2.284.47 2.03 2.03 0 0 0 .665-.442c.805-.805.792-2.128-.028-2.948ZM3.943 15.385a8.333 8.333 0 1 1 5.892 2.441 8.28 8.28 0 0 1-5.892-2.44Zm19.023 7.194a.871.871 0 0 1-.623.253h-.013a.95.95 0 0 1-.661-.28l-5.756-5.756a9.834 9.834 0 0 0 1.266-1.273l5.76 5.76c.364.364.377.946.027 1.296Z' fill='%231D1D1B'/%3E%3C/svg%3E");
  --icon-x-small: url("data:image/svg+xml,%3Csvg width='10' height='10' viewBox='0 0 10 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.14038 1L8.91856 8.77817' stroke='currentColor' stroke-width='1.7546' stroke-miterlimit='10' stroke-linecap='round'/%3E%3Cpath d='M1.14038 9L8.91856 1.22183' stroke='currentColor' stroke-width='1.7546' stroke-miterlimit='10' stroke-linecap='round'/%3E%3C/svg%3E%0A");

  /* ================ visuallyhidden ================ */

    --visuallyhidden {
      clip: rect(0 0 0 0);
      clip-path: inset(50%);
      height: 1px;
      overflow: hidden;
      position: absolute;
      white-space: nowrap;
      width: 1px;
    }

     /* To reorganie */

     --marketplaceValidationErrorFontStyles {
      font-size: 16px;
      line-height: 1.5;
      color: var(--c-pink-600);
     }

     --marketplacePill {
        white-space: nowrap;
        display: inline-block;
        color: #c08f1f;
        border: 1px currentColor solid;
        border-radius: 1em;
        font-size: 0.65rem;
        font-weight: 500;
        line-height: 1;
        padding: 0.3em 1em;
      }

  /* ================ Containers ================ */

  --marketplaceContainerStyles {
    width: 100%;
    max-width: var(--ContainerWidthLarge);
    margin-inline: auto;
    padding-inline: var(--spacing-s);
    text-align: start;
    @media (--viewportLarge) {
      padding-inline: var(--spacing-m);
    }
    @media (--viewportXLarge) {
      padding-inline: var(--spacing-m);
    }
  }

  --marketplaceContainerNarrowStyles {
    @apply --marketplaceContainerStyles;
    max-width: 840px;
  }

  /* ================ Spacing ================ */

  --spacing-xs: 10px;
  --spacing-s: 20px;
  --spacing-m: 40px;
  --spacing-l: 60px;
  --spacing-xl: 80px;

}

  /* ================ Custom media queries ================ */

  @custom-media --viewportXSmall (min-width: 360px);
  @custom-media --viewportSmall (min-width: 550px);
  @custom-media --viewportMedium (min-width: 768px);
  @custom-media --viewportLarge (min-width: 1024px);
  @custom-media --viewportLargeWithPaddings (min-width: 1128px);
  @custom-media --viewportLarger (min-width: 1360px);
  @custom-media --viewportXLarge (min-width: 1921px);
  @custom-media --viewportMobileOnly (max-width: 768px);
