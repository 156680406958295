@import '../../marketplace.css';

/* Todo 

— list item font size to review
— font styles/sizes to extract

*/

.root {
  background-color: var(--c-yellow-500);
  color: var(--c-white);
  font-size: 14px;
  font-weight: 500;
  @media (--viewportMedium) {
    font-size: 16px;
    margin-top: 100px;
  }
}

.root a {
  color: var(--c-white);
  &:hover {
    text-decoration: none;
    color: var(--c-yellow-200);
  }
}

.heading {
  font-size: 1.125em;
  margin: 0;
  font-weight: 700;
}

.wrapper {
  max-width: var(--ContainerWidthLarge);
  margin: 0 auto;
  padding: var(--spacing-m) var(--ContainerHorizontalPadding) 80px;

  @media (--viewportMedium) {
    
  }
}

.content {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-auto-rows: auto;
  row-gap: var(--spacing-m);
  column-gap: var(--spacing-s);

  
  @media (max-width: 360px) {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-s);
  }

  @media (--viewportXSmall) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-auto-rows: auto;
  }


  @media (--viewportMedium) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-auto-rows: auto;
  }

  @media (--viewportLarge) {
    grid-template-columns: 1fr 1fr 1fr 1fr 3fr;
    gap: var(--spacing-m);

  }
  @media (--viewportLarger) {
    grid-template-columns: 1fr 1fr 1fr 1fr 2fr;
  }
}

.list {
  margin: 1em 0;
}

.list a {
  display: block;
  padding: 0.5em 0;
}

.column {
  order: 2;
}

.mailingList {

  @media (max-width: 360px) {
    order: 1;
  }

  @media (--viewportXSmall) {
    grid-column: 1 / span 2;
    grid-row: 1;
  }

  @media (--viewportMedium) {
    grid-column: 1 / span 4;
    grid-row: 1;

  }

  @media (--viewportLarge) {
    grid-column: 5 / span 3;
    grid-row: 1;
  }

  @media (--viewportLarger) {
    grid-column: 5 / span 2;
    grid-row: 1;
  }
}

.mailingListFieldCombo {
  display: flex;
  flex-direction: column;
  @media (--viewportXSmall) {
    flex-direction: row;
  }
  @media (--viewportMedium) {
    max-width: calc( 50% - var(--spacing-s));
  }
  @media (--viewportLarge) {
    max-width: 100%;
  }
}

.mailingListFieldComboLabel {
  @apply --visuallyhidden;
}

.mailingListEmail {
  border-radius: 0;
  border: 0;
  background-color: var(--c-white);
  color: var(--c-black);
}
 
.mailingListButton {
  @apply --marketplaceButtonStyles;
  border-color: var(--c-green-500);
  background-color: var(--c-green-500);
  width: auto;
  &:hover {
    border-color: var(--c-green-700);
    background-color: var(--c-green-700);
  }
  &:focus {
    border-color: var(--c-green-900);
    background-color: var(--c-green-900);
  }
}

.mailingListFieldCombo label {
  @apply --visuallyhidden;
}

.legal {
  font-weight: 400;
  font-size: 0.75em;
}

.socialMedia {
  margin: 1em 0;
  display: flex;
  gap: 1em;
}

.socialMedia svg {
  width: 18px;
  height: 18px;
  object-fit: contain;
}