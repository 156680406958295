@import '../../marketplace.css';

:root {
  --SearchCategories_linkHover: {
    color: inherit;
    transition: var(--transitionStyleButton);

    &:hover {
      color: var(--marketplaceColor);
      text-decoration: none;
    }
  }
}

.listWrapper {
  /* Category list wrapper */
}

.list {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 0 0 40px;

  @media (--viewportLarge) {
    margin: -5px 0 50px;
  }
}

.item {
  color: var(--matterColorDark);
  font-size: 22px;
  font-weight: 500;
  line-height: 40px;
}

.item a {
  @apply --SearchCategories_linkHover;
  color: inherit;
}

.itemActive a {
  border-bottom: 4px solid var(--marketplaceColor);
}

.itemActive a:hover {
  color: inherit;
}

.divider {
  background: #000000;
  height: 4px;
  width: 18px;
  margin: 16px 0;
}
