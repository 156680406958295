@import '../../marketplace.css';
:root {
  --mashteadHeight: 140px;
  --filterButtonHeight: 36px;
  --menuDropShadow: 0 -2px 5px 0 hsl(220 3% 15% / calc(1% + 2%)),
    0 1px 1px -2px hsl(220 3% 15% / calc(1% + 3%)),
    0 2px 2px -2px hsl(220 3% 15% / calc(1% + 3%)),
    0 5px 5px -2px hsl(220 3% 15% / calc(1% + 4%)),
    0 9px 9px -2px hsl(220 3% 15% / calc(1% + 5%)),
    0 16px 16px -2px hsl(220 3% 15% / calc(1% + 6%));

  --filterMaxHeight: calc(100vh - var(--topbarHeightDesktop));
}

.root {
  width: 100%;

  font-weight: 500;
  z-index: 1000;
}

.container {
  flex-flow: column nowrap;
  position: relative;

  @media (--viewportMedium) {
    display: flex;

    visibility: visible;
    background-color: transparent;

    flex-flow: row wrap;
    gap: 10px;

    font-size: 14px;
    line-height: 1.2;
  }
}
.root ul {
  margin: 0;
  display: block;
}

.filterTitle {
  @apply --marketplaceH3FontStyles;
  padding: 20px;
  margin: 0;
  color: var(--c-grey-500);
  position: fixed;
  z-index: 1;
  top: 0;
  right: 0;
  left: 0;
  background-color: var(--c-white);
  visibility: hidden;
}

fieldset {
  border: 0;
  padding: 0;
  margin: 0;
  width: 100%;
}
legend {
  width: 100%;
}
legend span {
  font-size: 14px;
  padding: 14px 16px;
  display: block;
  border-color: var(--c-yellow-200);
  background-color: var(--c-yellow-200);
  @media (--viewportMedium) {
    padding: 10px 16px;
  }
}

.filter {
  position: relative;
  z-index: 1;
}

.filter[open] {
  z-index: 2;
}

.filter:has([type='checkbox']:checked):not([open]) .filterPrimaryButton {
  @media (--viewportMedium) {
    border-color: var(--c-yellow-500);
  }
}

.filter summary::-webkit-details-marker {
  display: none;
}

.filterButton {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  user-select: none;
  &:after {
    content: url("data:image/svg+xml,%3Csvg width='14' height='9' viewBox='0 0 14 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.6274 1.67163L6.97057 7.32849L1.31372 1.67163' stroke='currentColor' stroke-width='2'/%3E%3C/svg%3E%0A");
  }

  /* @media (--viewportMedium) {
    justify-content: flex-start;
  } */
}

[open] > .filterButton {
  &:after {
    rotate: 180deg;
  }
}

.filterPrimaryButton {
  padding: 14px 0;
  border-top: 1px var(--c-grey-200) solid;

  @media (--viewportMedium) {
    padding: 8px 16px;
    border: 2px var(--c-grey-100) solid;

    &:hover {
      border-color: var(--c-grey-200);
    }
  }
}

.filterSecondaryButton {
  @apply .filterButton;
  padding: 1em 16px;
  background-color: var(--c-yellow-150);
  border-top: 1px var(--c-white) solid;

  @media (--viewportMedium) {
    padding: 10px 16px;
  }
}

[open] > .filterPrimaryButton {
  @media (--viewportMedium) {
    border-color: var(--c-yellow-300);
    background-color: var(--c-yellow-300);
    &:hover {
      border-color: var(--c-yellow-300);
    }
  }
}

.filterPanel {
  background-color: var(--c-yellow-050);
  font-size: 14px;

  @media (--viewportMedium) {
    position: absolute;
    /* top: calc(1em +); */
    max-height: calc(var(--filterMaxHeight) - var(--filterButtonHeight) - 2rem);
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    min-width: 20ch;
  }
  /* box-shadow: var(--menuDropShadow); */
}

.filterAlignedRight .filterPanel {
  right: 0;
}

.filterPanel li {
  background-color: var(--c-yellow-050);
}

.filterPanel li label {
  margin: 0;
  padding: 14px 16px;
  display: flex;
  align-items: baseline;
  gap: 8px;
  font-size: inherit;
  white-space: nowrap;
  @media (--viewportMedium) {
    padding: 12px 16px;
  }
}

.filterPanel li label span {
  display: block;
  width: 100%;
}

.filterPanel :is(input[type='checkbox'], input[type='radio']) {
  width: auto;
}

.columns {
  columns: 2;
  column-gap: 0;
}

.columns li {
  break-inside: avoid;
}

.applyPanel {
  display: flex;
  gap: 10px;
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: var(--c-white);
  padding: 1rem;
  visibility: hidden;
}

.apply {
  @apply --marketplaceButtonStylesPrimaryGreen;
}
.reset {
  @apply --marketplaceButtonStylesGreenSecondary;
}

.apply,
.reset {
  width: 100%;
}

.close {
  @apply --marketplaceModalCloseStyles;
  visibility: hidden;
}

.closeText {
  @apply --marketplaceModalCloseText;
  @apply --visuallyhidden;
}

.closeIcon {
  @apply --marketplaceModalCloseIcon;
}

[aria-expanded='true'] .container {
  @media (max-width: 768px) {
    display: flex;
    position: fixed;
    inset: 0;
    padding: 64px 1rem 80px;
    background-color: #fff;
    overflow-y: auto;
  }
}

[aria-expanded='true'] :is(.filterTitle, .applyPanel, .close) {
  @media (max-width: 768px) {
    visibility: visible;
  }
}

input[type="checkbox"] {
    -webkit-appearance: none;
    appearance: none;
    margin: 0;
    min-width: .875em;
    height: .875em;
    border-radius: .25em;
    border: 1px var(--c-grey-300) solid;
    background-color: #FFF;
    display: grid;
    place-content: center;

    /* transform: translateY(-0.075em); */
    &:checked {
      background-color: var(--c-yellow-500);
      border-color: var(--c-yellow-500);
    }
}

input[type="checkbox"]::before {
  content: "";
    width: .875em;
    height: .875em;
  transform: scale(0);
  transition: 120ms transform ease-in-out;
  background: url("data:image/svg+xml,%3Csvg fill='none' height='14' width='14' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m5.828 9.364.708-.707m0 0L11.192 4M6.536 8.657l.707.707m-.707-.707L4 6.12' stroke='%23fff' stroke-width='2'/%3E%3C/svg%3E") no-repeat 50% 50%;
  background-size: 100% 100%;
}

input[type="checkbox"]:checked::before {
  transform: scale(1);
}