@import '../../marketplace.css';

.recentlyListed {
  overflow: hidden;
}

.listingCards {
  margin: 25px 0;
  margin-bottom: 0;
  display: flex;
  flex-wrap: wrap;

  @media (--viewportMedium) {
    display: flex;
    flex-wrap: wrap;
    margin: 70px -25px 0;
  }
}

.listingCard {
  margin-bottom: 20px;
  width: 50%;
  padding: 0;

  @media (--viewportMedium) {
    padding: 0 25px;
    width: 50%;
  }

  @media (--viewportLarge) {
    margin-bottom: 0;
    width: 25%;
  }
}

.listingCard:nth-child(even) {
  padding-left: 10px;

  @media (--viewportMedium) {
    padding-left: 25px;
  }
}

.listingCard:nth-child(odd) {
  padding-right: 10px;
  @media (--viewportMedium) {
    padding-right: 25px;
  }
}

.listingContainer {
  display: contents;
}
