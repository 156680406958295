@import '../../marketplace.css';

.root {
  margin: 20px 0;
  @media (--viewportMedium) {
    margin: 40px 0;
  }
}

.input {
  border-color: var(--attentionColor);
  border-radius: 0;
  /* line-height: 28px; */
}

.input::placeholder { color: var(--c-grey-300);}
.input::-webkit-input-placeholder { color: var(--c-grey-300);}
textarea.input {
  box-sizing: border-box;
}

.inputSuccess {
  /* border-color: var(--successColor); */
}

.inputError {
  border-color: var(--failColor);
}

.textarea {
  height: auto;
}
