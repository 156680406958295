@import '../../marketplace.css';

.divider {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.titleBorder {
  border-bottom: 2px solid var(--marketplaceColor);
  flex: 1 0 auto;
  height: 5px;
}

.title {
  margin: 0;
  padding: 0 23px;
  font-size: 24px;
  line-height: 1.5;
  color: var(--marketplaceColor);
  margin-bottom: 0;

  @media (--viewportMedium) {
    font-size: 30px;
  }
}
