@import '../../octopusClub.css';

.root {
  display: flex;
  flex-direction: column;
}

.listingCards {
  @apply --gridStyle;
  margin: 20px auto 40px;
  padding-inline:  var(--ContainerHorizontalPadding);
  width: 100%;
  max-width: var(--ContainerWidthLarge);
}


.searchFooter .searchFooterInner {
  margin: 0;

  @media (--viewportMedium) {
    padding-left: 0;
    padding-right: 0;
  }
}

.loadMoreButtonWrap {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 32px;
}

.loadMoreButton {
  display: inline-block;
  min-height: 36px;
  padding: 0 10px;
  width: auto;
}

.soldAlertMainContainer {
  margin-bottom: 20px;
  padding-left: 10px;
  padding-right: 10px;

  @media (--viewportMedium) {
    width: 46%;
    padding-left: 10px;
    padding-right: 0;
  }

  @media (--viewportLarge) {
    width: 50%;
    padding-left: 45px;
  }
}

.soldContainer {
  background: var(--marketplaceColor);
  padding: 10px 19px;
  font-size: 18px;
  line-height: 1.5;
  color: #ffffff;
  font-weight: 500;

  @media (--viewportMedium) {
    font-size: 15px;
  }
}

.soldContainer > a {
  color: #ffffff;
  text-decoration: underline;
}

.listingContainer {
  display: contents;
}

.disabledSold {
  pointer-events: none;
  cursor: default;
}
