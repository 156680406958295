@import '../../marketplace.css';

.root {
  position: relative;
  display: inline-block;
}

.label {
  @apply --marketplaceButtonStylesSecondary;
  @apply --marketplaceSearchFilterLabelFontStyles;

  padding: var(--marketplaceButtonSmallDesktopPadding);
  width: auto;
  height: auto;
  min-height: 0;
  border-radius: 4px;

  &:focus {
    outline: none;
    background-color: var(--matterColorLight);
    border-color: transparent;
    text-decoration: none;
    box-shadow: var(--boxShadowFilterButton);
  }
}

.labelSelected {
  @apply --marketplaceButtonStyles;
  @apply --marketplaceSearchFilterLabelFontStyles;
  font-weight: var(--fontWeightSemiBold);

  padding: var(--marketplaceButtonSmallDesktopPadding);
  width: auto;
  height: auto;
  min-height: 0;
  border-radius: 4px;
  border: 1px solid var(--marketplaceColor);

  &:hover,
  &:focus {
    border: 1px solid var(--marketplaceColorDark);
  }
}

.fieldGroup {
  margin: 0;
  padding: 0;
  border: none;
}

.fieldGroupPlain {
  margin: 0;
  /* padding-left: 20px;
  padding-bottom: 30px; */
  padding: 0;
  border: none;
  padding-top: 8px;

  @media (--viewportMedium) {
    padding: 8px 15px 0 0;
  }
}

.list {
  margin: 0;

  &:not(:first-child)::before {
    content: '';
    display: block;
    width: calc(50% - 12px);
    height: 1px;
    background-color: var(--marketplaceColor);
    margin-bottom: 8px;
    /* border-top: 1px solid var(--marketplaceColor); */

    @media (--viewportMedium) {
      width: 100%;
    }
  }
}

.item {
  padding: 2px 0;

  /* Fix broken multi-column layout in Chrome */
  page-break-inside: avoid;

  @media (--viewportMedium) {
    padding: 4px 0;
  }
}

.itemText {
  @apply --marketplaceListingAttributeFontStyles;
  color: var(--matterColor);
  margin-top: -1px;
  margin-bottom: 1px;
  cursor: pointer;
  font-size: 13px;
  line-height: 24px;

  @media (--viewportMedium) {
    color: var(--matterColorDark);
    font-size: 16px;
    line-height: 20px;
  }
}
