@import '../../marketplace.css';

:root {
}

.root {
  position: relative;
  width: 100%;
  max-width: var(--ContainerWidthLarge);
  margin-inline: auto;
  text-align: start;

  display: flex;
  flex-flow: column nowrap;
  @media (--viewportMedium) {
    flex-flow: row nowrap;
    padding-inline: var(--spacing-m);
  }
  @media (--viewportLarge) {
    padding: var(--spacing-l) var(--spacing-l);
  }
  @media (--viewportXLarge) {
    padding-inline: var(--ContainerHorizontalPaddingMedium);
  }
}

.image {
  width: 100%;
  @media (--viewportMedium) {
    width: 50%;
  }
}

.image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  aspect-ratio: 1;
}

.container {
  width: 100%;

  margin-inline: auto;
  display: flex;
  flex-flow: column;
  justify-content: center;
  text-align: center;
  @media (--viewportMedium) {
    width: 50%;
  }
}

.title {
  @apply --marketplaceH2FontStyles;
  margin: 0;
}

.footer {
  margin-top: var(--spacing-s);
}

.button {
  @apply --marketplaceButtonStyles;
}

.root:global(.right) {
  @media (--viewportMedium) {
    flex-flow: row-reverse;
  }
}

.root:global(.negative) .container {
  padding: var(--spacing-m) var(--spacing-s);
  @media (--viewportMedium) {
    padding: var(--spacing-m);
  }
  @media (--viewportLarge) {
    padding: var(--spacing-l);
  }
}

.root:global(.negative) .button,
.root:global(.negative.yellow) .button {
  background-color: #fff;
  border-color: #fff;
  color: var(--c-yellow-500);
  &:hover {
    opacity: 0.8;
  }
}

.root:global(.negative.green) .button {
  color: var(--c-green-500);
}

.root:global(.negative.pink) .button {
  color: var(--c-pink-500);
}

.root:global(.yellow) {
  color: var(--c-yellow-500);
}

.root:global(.pink) {
  color: var(--c-pink-500);
}

.root:global(.green) {
  color: var(--c-green-500);
}

.root:global(.negative) {
  color: #fff;
}

.root:global(.negative.yellow) .container {
  background-color: var(--c-yellow-500);
}
.root:global(.negative.pink) .container {
  background-color: var(--c-pink-500);
}
.root:global(.negative.green) .container {
  background-color: var(--c-green-500);
}

.root:global(.overlay.negative.yellow) .container {
  @media (--viewportMobileOnly) {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    padding: var(--spacing-s);
    z-index: 2;
    background-color: transparent;
    align-items: flex-start;
    justify-content: flex-start;
    text-align: left;
    font-weight: var(--fontWeightSemiBold);
  }
}
.root:global(.overlay.negative.yellow) p {
  @media (--viewportMobileOnly) {
    margin: 0;
  }
}

.root:global(.overlay.negative.yellow) .footer {
  @media (--viewportMobileOnly) {
    margin-top: auto;
    margin-inline: auto;
  }
}

.root:global(.overlay.negative.yellow) .button {
  @media (--viewportMobileOnly) {
    background-color: var(--c-yellow-500);
    border-color: var(--c-yellow-500);
    color: var(--c-white);
    &:hover {
      background-color: var(--c-yellow-600);
      border-color: var(--c-yellow-600);
      opacity: 1;
    }
  }
}
