@import '../../marketplace.css';

:root {
}

.root {
  @apply --marketplaceContainerStyles;
  position: relative;
  background-color: var(--c-yellow-500);
  min-height: 100vw;
  height: 1px;
  padding: 0;

  display: flex;
  place-content: center;

  @media (--viewportMedium) {
    min-height: 720px;
  }
}

:global(.aspectratiomobile-1x1) {
  @media (max-width: 767px) and (orientation: portrait) {
    min-height: 100vw;
  }
}

:global(.aspectratiomobile-3x2) {
  @media (max-width: 767px) and (orientation: portrait) {
    min-height: calc(3 / 2 * 100vw);
  }
}

:global(.aspectratiomobile-4x3) {
  @media (max-width: 767px) and (orientation: portrait) {
    min-height: calc(4 / 3 * 100vw);
  }
}

.content {
  position: relative;
  z-index: 2;

  text-align: center;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: column;
  align-items: center;
  margin-block: auto;
  height: 100%;

  max-width: 840px;
  margin-inline: auto;
  padding:  var(--spacing-m) var(--spacing-s);

  color: var(--c-white);
  font-weight: var(--fontWeightSemiBold);

  @media (--viewportMedium) {
    padding: var(--spacing-l);
    font-size: 1.2em;
    line-height: 1.5;
  }
}

.contentTop {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 50%;

}

.contentBottom {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 100%;
}
.contentBottom p {
  margin-block-end: 0;
}

.contentActions {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  gap: var(--spacing-s);
  margin: 20px 0 0;

  @media (--viewportMedium) {
    gap: 36px;
  }
}

.button {
  @apply --marketplaceButtonStyles;
  display: block;
  min-width: 150px;
  min-width: calc( ( 100vw - 60px ) / 2 );

  @media (--viewportMedium) {
    width: auto;
    min-width: 200px;
  }
}

.button:global(.pink) { 
  @apply --marketplaceButtonPinkStyles;
  
}

.button:global(.green) { 
  @apply --marketplaceButtonGreenStyles;
}

.button:global(.blue) { 
  @apply --marketplaceButtonBlueStyles;
}

.background {
  position: absolute;
  inset: 0;
  display: flex;
  height: 100%;
  width: 100%;
  object-fit: cover;
  z-index: 1;
  filter: brightness(0.9);
}

.headline {
  @apply --marketplaceH1FontStyles;
  margin: 0;
  @media (--viewportMobileOnly) {
    font-size: clamp(22px, 6.875vw, 30px) !important;
    line-height: 1.125;
    padding-inline: 1em;
  }
}
