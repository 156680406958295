@import '../../marketplace.css';

:root {
  --contentPaddingSmall: 20px;
  --contentGapSmall: 20px;
  --contentPaddingLarge: 60px;
  --contentGapLarge: 36px;
}

.root {
  @apply --marketplaceSmallFontStyles;
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column nowrap;
  text-align: center
}


.title {
  @apply --marketplaceH2FontStyles;
  margin: 0;
}

.copy {
  margin-block: 20px;
}

.footer {
  margin-top: auto;
}

.button {
  @apply --marketplaceButtonStyles;
  display: inline-block;
  width: auto;
}

.root:global(.yellow) .title {
  color: var(--c-yellow-500);
}

.root:global(.yellow) .button {
  @apply --marketplaceButtonYellowStyles;
}

.root:global(.pink) .title {
  color: var(--c-pink-500);
}

.root:global(.pink) .button {
  @apply --marketplaceButtonPinkStyles;
}

.root:global(.green) .title {
  color: var(--c-green-500);
}

.root:global(.green) .button {
  @apply --marketplaceButtonGreenStyles;
}


