@import '../../marketplace.css';

:root {
}

.root {
  @apply --marketplaceContainerStyles;
  position: relative;
  width: 100%;
  margin-block: var(--spacing-s);
  /* padding-inline: var(--spacing-s); */
  @media (--viewportMedium) {
    margin-block: var(--spacing-m);
  }
}

.content {
  display: grid;
  gap: var(--spacing-s);
  grid-template-columns: repeat(2, 1fr);
  @media (--viewportMedium) {
    gap: var(--spacing-m);
    grid-template-columns: repeat(4, 1fr);
  }
}
