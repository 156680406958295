@import './assets/sanitize.css';
@import './octopusClub.css';
@import './octopusClubIcons.css';


/* ================ Fonts ================ */

body {
  font-family: var(--fontFamily);
  -webkit-font-smoothing: antialiased; /* http://szafranek.net/blog/2009/02/22/font-smoothing-explained/ */
  -moz-osx-font-smoothing: grayscale; /* http://szafranek.net/blog/2009/02/22/font-smoothing-explained/ */
  text-rendering: optimizeSpeed;

  background-color: var(--matterColorLight);
}

::selection {
  background: var(--marketplaceColor); /* WebKit/Blink Browsers */
  color: var(--matterColorLight);
}

::-moz-selection {
  background: var(--marketplaceColor); /* Gecko Browsers */
  color: var(--matterColorLight);
}

a {
  @apply --marketplaceLinkStyles;
}

h1 {
  @apply --marketplaceH1FontStyles;
}
h2 {
  @apply --marketplaceH2FontStyles;
}
h3 {
  @apply --marketplaceH3FontStyles;
}
h4 {
  @apply --marketplaceH4FontStyles;
}
h5 {
  @apply --marketplaceH5FontStyles;
}
h6 {
  @apply --marketplaceH6FontStyles;
}

input,
textarea,
select {
  @apply --marketplaceDefaultFontStyles;
  line-height: 24px;
}

p,
pre {
  /* @apply --marketplaceBodyFontStyles; */
}

b,strong {
  font-weight: var(--fontWeightSemiBold);
}

/* ================ Normalisations ================ */

* {
  -webkit-tap-highlight-color: transparent;
}

html {
  @apply --marketplaceDefaultFontStyles;
  color: var(--matterColor);
  padding: 0;
  margin: 0;
  /* scroll-behavior: smooth; */
  @media (max-width: 767px) {
    scroll-padding-block-start: 80px;
  }
}

ul {
  list-style: none;
  padding-left: 0;
}

legend,
label {
  font-weight: var(--fontWeightSemiBold);
  display: block;
  margin: 0 0 6px;
  font-size: 16px;
  @media (--viewportMedium) {

  }
}

button {
  font-family: var(--fontFamily);
}

select {
  @apply --marketplaceSelectStyles;
}

input {
  @apply --marketplaceInputStyles;
}

textarea {
  @apply --marketplaceInputStyles;

  /* Todo: All textareas are using auto-sizing extension which currently calculates required space incorrectly when box-sixing is "border-box" */
  box-sizing: content-box;
  /* padding: 0 0 10px 0; */

  &::placeholder {
    /* padding: 0 0 10px 0; */
  }

  @media (--viewportMedium) {
    /* padding: 4px 0 10px 0; */
  }
}

:global(.fontsLoaded) {
  & body {
    font-family: var(--fontFamily);
  }
  & button {
    font-family: var(--fontFamily);
  }
}

:global(.drift-frame-controller) {
  transform: scale(0.9) translateZ(0) !important;
  transform-origin: right bottom;
}

:global(.visually-hidden) {
  @apply --visuallyhidden;
}