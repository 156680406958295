@import '../../marketplace.css';

.root {
}

.tabs {
  display: none;

@media (--viewportMedium) {
  display: flex;
  justify-content: flex-end;
  height: 55px;
  align-items: flex-end;
  padding: 13px 24px 0 24px;
}

@media (--viewportLarge) {
  padding: 0 36px;
}
}

.tab {
  display: flex;
  align-items: flex-end;
  height: 100%;
  margin-left: 16px;

&:first-child {
   margin-left: 0;
 }

@media (--viewportLarge) {
  margin-left: 24px;
}
}

.listingPanel {
@media (--viewportMedium) {
  margin: 32px auto 0 auto;
  width: 100%;
}

@media (--viewportLarge) {
  margin: 0;
}
}

.message { text-align: center; margin-block-start:0;}

.listingCards {
  @apply --gridStyle;
  margin-block-end: var(--spacing-m);
  @media (--viewportMedium) {
    margin-block-end: var(--spacing-l);
  }
}


.pagination {
  /* margin-top: auto; pushes pagination to the end of the page. */
  margin: auto 24px 0 24px;

@media (--viewportLarge) {
  margin: auto 36px 0 36px;
}
}
