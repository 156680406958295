@import '../../marketplace.css';

.banner {
  align-items: center;
  background: var(--marketplaceColor);
  display: flex;
  height: 360px;
  justify-content: center;
  padding: 0 20px;
  width: 100%;

  @media (--viewportMedium) {
    height: auto;
    padding: 0 30px;
    width: 50%;
  }
}

.bannerFlipped {
  composes: banner;
  order: 2;
}

.bannerInner {
  max-width: 380px;
  text-align: center;
}

.title {
  color: var(--matterColorLight);
  font-size: 30px;
  font-weight: 400;
  line-height: 1.3;
  margin-bottom: 40px;
  text-align: center;

  @media (--viewportMedium) {
    font-size: 26px;
  }

  @media (--viewportLarge) {
    font-size: 30px;
  }
}

.button {
  background: var(--matterColorLight);
  color: var(--matterColorDark);
  display: inline-block;
  font-size: 14px;
  letter-spacing: 2.8px;
  min-height: 45px;
  padding: 12px 30px;
  text-transform: uppercase;
  width: auto;
  font-weight: 500;

  @media (--viewportMedium) {
    font-size: 12px;
    padding: 10px 15px;
  }

  @media (--viewportLarge) {
    font-size: 14px;
    padding: 12px 30px;
  }

  &:hover {
    background: var(--marketplaceColorDark);
    text-decoration: none;
  }
}
