@import '../../marketplace.css';

.root {
  text-align: center;
  margin-bottom: var(--spacing-s);

  @media (--viewportMedium) {
    margin-bottom: var(--spacing-m);
  }
}

.tabs {
  display: flex;
  flex-direction: row;
  overflow-x: auto;
  /* justify-content: center; */
  align-items: baseline;
  margin: 0 -20px;
  padding-inline: 20px;
  -webkit-overflow-scrolling: touch;
  text-align: center;

  -ms-overflow-style: none;
  scrollbar-width: none;
}

.tabs::-webkit-scrollbar {
  display: none;
}

.tab:first-child {
  margin-inline-start: auto;
}
.tab:last-child {
  margin-inline-end: auto;
}

.title {
  text-align: center;
}
