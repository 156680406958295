@import '../../marketplace.css';

:root {

}

.root {
  display: flex;
  flex-flow: column;
  align-items: center;
  padding: 40px 20px;
}

.information { font-size: .7em;}

progress{
  /* Reset the default appearance */
  -webkit-appearance: none;
   appearance: none;
  width: 100%;
  max-width: 300px;
  height: 2px;
  margin: 20px 0;
}

progress::-webkit-progress-bar {
  background-color: #D8DAE5;
}

progress::-webkit-progress-value {
  background-color: #000;
}

.loadMoreButton {
  @apply --marketplaceButtonStylesSecondary;
  margin-top: 20px;
}