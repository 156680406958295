@import '../../marketplace.css';

:root {
}

.root {
  @apply --marketplaceContainerStyles;
  text-align: center;
  padding: var(--spacing-s);
  @media (--viewportMedium) {
    padding-block: var(--spacing-m);
  }
}

.root:global(.align-mobile-left) {
  @media (--viewportMobileOnly) {
    text-align: left;
  }
}

.container {
  max-width: 840px;
  margin-inline: auto;
  display: flex;

  flex-flow: column nowrap;
  gap: var(--spacing-s);
  @media (--viewportMedium) {
    align-items: center;
  }
}

.container :first-child { margin-block-start: 0;}
.container :last-child { margin-block-end: 0;}

.title {
  @apply --marketplaceH2FontStyles;
  margin: 0;
}

.footer {
  margin-top: 20px;
  @media (--viewportMedium) {
    margin-top: 60px;
  }
}

.button {
  @apply --marketplaceButtonStyles;
}

.root:global(.negative) {
  padding: 40px;
  @media (--viewportMedium) {
    padding: 80px;
  }
}

.root:global(.negative) a:not(.button) {
  color: #fff;
  text-decoration: underline;
}

.root:global(.negative) .button,
.root:global(.negative.yellow) .button {
  background-color: #fff;
  border-color: #fff;
  color: var(--c-yellow-500);
  &:hover {
    opacity: 0.8;
  }
}

.root:global(.negative.green) .button {
  color: var(--c-green-500);
}

.root:global(.negative.pink) .button {
  color: var(--c-pink-500);
}

.root:global(.negative.blue) .button {
  color: var(--c-blue-500);
}

.root:global(.yellow) {
  color: var(--c-yellow-500);
}

.root:global(.pink) {
  color: var(--c-pink-500);
}

.root:global(.green) {
  color: var(--c-green-500);
}

.root:global(.negative) {
  color: #fff;
}

.root:global(.negative.yellow) {
  background-color: var(--c-yellow-500);
}
.root:global(.negative.pink) {
  background-color: var(--c-pink-500);
}
.root:global(.negative.green) {
  background-color: var(--c-green-500);
}

.root:global(.negative.blue) {
  background-color: var(--c-blue-500);
}

.root:global(.large) {
  font-weight: var(--fontWeightSemiBold);
  font-kerning: normal;
  @media (--viewportMedium) {
    font-size: 1.2em;
  }
}
.root:global(.large) .container {
  max-width: 100%;
}

.root:global(.large) strong {
  display: block;
  margin-inline: 0.25em;
  font-size: 3em;
  vertical-align: -0.25em;
  font-weight: var(--fontWeightSemiBold);

  @media (--viewportMedium) {
    font-size: 3em;

    display: inline-block;

  }
}

.logo {
  margin-inline: auto; 
  max-width: 60%;
  height: auto;
}