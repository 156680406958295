@import '../../marketplace.css';

.root {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: var(--ContainerWidthLarge, 1440px;);
  padding: 0 var(--ContainerHorizontalPadding, 20px);
  margin: 32px auto;
  gap: 32px;

  @media (--viewportSmall) {
    justify-content: center;
  }
}

.prev,
.next {
  display: block;
  font-size: 0.8rem;
  width: 2em;
  height: 2em;
  border-radius: 1em;
  overflow: hidden;
  display: grid;
  place-content: center;
}

.arrowIcon {
  stroke: var(--c-black);

  &:hover {
    stroke: var(--marketplaceColor);
  }
}

.disabled,
.disabled:hover {
  fill: var(--matterColorAnti);
  stroke: var(--matterColorAnti);
}

.pageNumberList {
  display: flex;
  justify-content: space-between;
  gap: 32px;
}

/**
 * Using 'justify-content: space-between;' we can deal with very narrow mobile screens.
 * However, since the length of pageNumberList can vary up to 7,
 * we need to keep track of how much space is allocated for the list
 * Maximum length of pageNumbersNavLinks is 7 (e.g. [1, '…', 4, 5, 6, '…', 9])
 */
/* .pageNumberList1Items {
  flex-basis: 30px;
}

.pageNumberList2Items {
  flex-basis: 60px;
}

.pageNumberList3Items {
  flex-basis: 100px;
}

.pageNumberList4Items {
  flex-basis: 160px;
}

.pageNumberList5Items {
  flex-basis: 200px;
}

.pageNumberList6Items {
  flex-basis: 250px;
}

.pageNumberList7Items {
  flex-basis: 275px;
} */

.toPageLink {
  /* Dimensions */
  font-weight: 500;
  font-size: 0.8rem;
  border-radius: 1em;
  width: 2em;
  height: 2em;
  text-align: center;
  line-height: 2em;

  /* Color for svg icons */
  color: var(--matterColor);

  &:hover {
    text-decoration: none;
    color: var(--marketplaceColor);
  }
}

.currentPage {
  /* Dimensions */
  background-color: var(--marketplaceColor);

  /* Color */
  color: var(--c-white);
  &:hover {
    color: var(--c-white);
  }
}

.paginationGap {
  /* Dimensions */
  font-weight: 500;
  font-size: 0.8rem;
  width: 2em;
  height: 2em;
  text-align: center;
  line-height: 1.5em;
}
