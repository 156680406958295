@import '../../marketplace.css';

.contentWrapper { 
  max-width: calc( 8 / 12 * var(--ContainerWidthLarge)); 
  margin: auto;
}

.contentMain {}

section { margin: 3em 0; }
.swatches {
  display: grid;
  grid-template-columns: repeat(9, fit-content(36px));
  grid-auto-rows: auto;
  gap:9px;
}
.swatch {
  display: inline-block;
  width: 36px;
  height: 36px;
  border-radius: 18px;
}

.yellow100 { background-color: var(--c-yellow-100); }
.yellow200 { background-color: var(--c-yellow-200); }
.yellow300 { background-color: var(--c-yellow-300); }
.yellow400 { background-color: var(--c-yellow-400); }
.yellow500 { background-color: var(--c-yellow-500); }
.yellow600 { background-color: var(--c-yellow-600); }
.yellow700 { background-color: var(--c-yellow-700); }
.yellow800 { background-color: var(--c-yellow-800); }
.yellow900 { background-color: var(--c-yellow-900); }

.pink100 { background-color: var(--c-pink-100); }
.pink200 { background-color: var(--c-pink-200); }
.pink300 { background-color: var(--c-pink-300); }
.pink400 { background-color: var(--c-pink-400); }
.pink500 { background-color: var(--c-pink-500); }
.pink600 { background-color: var(--c-pink-600); }
.pink700 { background-color: var(--c-pink-700); }
.pink800 { background-color: var(--c-pink-800); }
.pink900 { background-color: var(--c-pink-900); }

.green100 { background-color: var(--c-green-100); }
.green200 { background-color: var(--c-green-200); }
.green300 { background-color: var(--c-green-300); }
.green400 { background-color: var(--c-green-400); }
.green500 { background-color: var(--c-green-500); }
.green600 { background-color: var(--c-green-600); }
.green700 { background-color: var(--c-green-700); }
.green800 { background-color: var(--c-green-800); }
.green900 { background-color: var(--c-green-900); }
