@import '../../marketplace.css';

/* @custom-media --vpMax767 (max-width: 767px); */

.root {
}

.pageRoot {
  display: flex;
  padding-bottom: 0;
}

.loadingText {
  margin: 24px;
  position: fixed;
  inset: 0;
  display: grid;
  place-content: center;
  font-weight: var(--fontWeightSemiBold);
}

.errorText {
  color: var(--failColor);
  margin: 24px;
  text-align: center;
}

.listingContainer {
  @apply --marketplaceContainerStyles;
  @media (--viewportMedium) {
    display: flex;
    flex-direction: row;
    gap: var(--spacing-m);
  }
}

.listingSidebar {
  display: none;
}

.sectionImages {
  margin-inline: -20px;
  position: relative;
  z-index: 1;

  @media (--viewportMedium) {
    width: 100%;
    position: sticky;
    top: 0;
    align-self: flex-start;
    margin-inline: 0;
  }
}

/* Firefox doesn't support image aspect ratio inside flexbox */
.aspectWrapper {
  position: relative; /* allow positioning own listing action bar */
  padding-bottom: 100%; /* 3:2 Aspect Ratio */
  /* background-color: var(--matterColorNegative); */ /* Loading BG color */

  /* Image carousel can be opened from the image, therefore we should show a pointer */
  cursor: pointer;
  width: 100%;
  height: 0;
}

.carouselNavigation {
  align-items: center;
  display: flex;
  justify-content: center;
  margin: 20px 0;
  width: 100%;
}

.arrow,
.dot {
  display: block;
}

.arrow {
  display: block;
  height: 16px;
  line-height: 1;
  margin: 0 10px;
  width: 12px;
}

.dot {
  border-radius: 100%;
  border: 1px solid var(--c-grey-400);
  height: 8px;
  margin: 0 5px;
  width: 8px;
}

.dotActive {
  border-color: #000;
  background-color: #000;
}

.actionBar {
  position: absolute;
  display: flex;
  justify-content: space-between;
  gap: .5em;
  top: 1em;
  left: 1em;
  right: 4em;
  color: var(--c-yellow-400);
  background-color: rgba(0,0,0,.85);
  z-index: 1; /* bring on top of mobile image */
  font-size: .875em;
  font-weight: var(--fontWeightSemiBold);
  z-index: 2;

  /* Action bar prevents the image click events going to the parent and
   should not show a pointer */
  cursor: initial;
  padding: .45em 1em;
}

.ownListingText {
  /* @apply --marketplaceH4FontStyles; */
  margin: 0;
  /* margin: 1em 2em; */
}

.ownListingTextPendingApproval {
  color: var(--attentionColor);
}

.closedListingText {
  @apply --marketplaceH4FontStyles;
  /* margin: 1em 2em; */
  text-align: center;
  width: 100%;
}

.editListingLink {
  /* @apply --marketplaceH4FontStyles; */
  flex-shrink: 0;
  margin: 0;
  
  color: var(--c-white);
  transition: var(--transitionStyleButton);

  &:hover {
    color: var(--matterColorLight);
    text-decoration: none;
  }

  @media (--viewportMedium) {
    margin: 0;
  }
}

.editIcon {
  margin: -6px 7px 0 0;
}

.rootForImage {
  /* Layout - image will take space defined by aspect ratio wrapper */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: var(--transitionStyleButton);
}

.viewPhotos {
  @apply --marketplaceTinyFontStyles;
  font-weight: var(--fontWeightMedium);

  /* Position and dimensions */
  position: absolute;
  bottom: 19px;
  right: 24px;
  margin: 0;
  padding: 8px 13px 6px 13px;

  /* Colors */
  background-color: var(--matterColorLight);

  /* Borders */
  border: none;
  border-radius: var(--borderRadius);

  cursor: pointer;
  transition: var(--transitionStyleButton);

  &:hover {
    background-color: var(--marketplaceColor);
    color: var(--matterColorLight);
  }

  @media (--viewportMedium) {
    margin: 0;
  }
}

.carouselModalScrollLayer {
  width: 100vw;
  height: 100vh;
  background-color: rgba(1, 1, 1, 0.92);
}

.carouselModalContainer {
  width: 100%;
  height: 100%;
}

.mainContent {
  width: 100%;
}

.bookingPanel {
  @media (--viewportLarge) {
    display: block;
    margin-top: 79px;
    margin-left: 60px;
    border-left-style: solid;
    border-left-width: 1px;
    border-left-color: var(--matterColorNegative);

    padding-left: 60px;

    /* The calendar needs 312px */
    flex-basis: 312px;
    flex-shrink: 0;
  }
}

.sectionAvatar {
  /* Position (over the listing image)*/
  margin-left: 24px;
  margin-top: -31px;

  /* Rendering context to the same lavel as listing image */
  position: relative;

  /* Flex would give too much width by default. */
  width: 60px;

  @media (--viewportMedium) {
    position: absolute;
    top: -49px;
    margin-left: 0;
    margin-top: 0;
  }
}

.avatarMobile {
  display: flex;

  @media (--viewportMedium) {
    display: none; /* Hide the medium avatar from the bigger screens */
  }
}

.avatarDesktop {
  display: none; /* Hide the large avatar from the smaller screens */

  @media (--viewportMedium) {
    display: flex;
  }
}

.sectionHeading {
  justify-content: space-between;
  display: flex;
  flex-flow: row wrap;
  gap: var(--spacing-xs);

  @media (--viewportMedium) {
    display: flex;
    flex-flow: column nowrap;
    gap: var(--spacing-s);
  }
}

.priceContainer {
}

.oldPriceValue {
  color: #aaaaaa;
  text-decoration: line-through;
  margin-right: 5px;
}

.desktopPerUnit {
  /* Font */
  @apply --marketplaceH5FontStyles;
  color: var(--marketplaceColor);
  font-size: 20px;
  margin-top: 0;

  /* @media (--vpMax767) {
    margin-bottom: 0;
  } */

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.heading {
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: var(--spacing-xs);
  @media (--viewportMedium) {
    gap: var(--spacing-s);
  }
}

.title,
.priceValue {
  /* Font */
  font-weight: var(--fontWeightSemiBold);
  font-size: 1.2em;
  line-height: 1.5;

  /* Layout */
  width: 100%;
  margin: 0;

  @media (--viewportMedium) {
    max-width: 20ch;
    font-size: 1.6em;
    line-height: 1.5;
  }
}

.buyButton,
.saleButton,
.putOnSaleButton {
  display: block;
  width: 100%;
  @media (--viewportMedium) {
    display: inline-block;
    max-width: 50%;
  }
}

.saleButton,
.putOnSaleButton {
  /* background-color: var(--matterColorAnti); */
}

.saleButton:hover,
.putOnSaleButton:hover {
  /* background-color: var(--matterColorAnti); */
}

.putOnSaleButton {
  @apply --marketplaceButtonStylesSecondary;
}

.headerDetails {
  font-size: 0.8rem;
  display: flex;
  flex-flow: row wrap;
  gap: 1em;
  /* align-items: baseline; */
  margin: 0;
}

.conditionBadge {
  @apply --marketplacePillStyles;
}

.author {
  & a {
    text-decoration: underline;
  }
}

.authorNameLink {
  color: var(--matterColor);
}

.contactWrapper {
  display: none;

  @media (--viewportMedium) {
    display: inline;
  }
}

.separator {
  margin: 0 6px;
}

.contactLink {
  @apply --marketplaceLinkStyles;
  @apply --marketplaceH5FontStyles;
  margin: 0;
}

.sectionDescription,
.sectionCondition {
  margin: 30px 0;
}

.descriptionTitle,
.conditionTitle {
  /* Font */
  @apply --marketplaceH3FontStyles;
  color: var(--matterColorDark);
  font-size: 16px;
  font-weight: 500;
  line-height: 26px;

  margin: 0 0 2px;
}

.sectionFeatures {
  margin-bottom: 32px;

  @media (--viewportMedium) {
    margin-bottom: 52px;
  }
}

.sectionFeaturesTable {
  font-size: 0.875em;
  @media (--viewportMedium) {
    font-size: 0.8em;
  }
}

.sectionFeaturesTable th,
.sectionFeaturesTable td {
  text-align: left;
  font-weight: normal;
  vertical-align: top;
  padding: 0.375em 1em 0.375em 0;
}

.sectionFeaturesTable th {
  padding-right: 2em;
}

.featuresTitle {
  /* Font */
  @apply --marketplaceH3FontStyles;
  color: var(--matterColorAnti);

  margin-top: 0;
  margin-bottom: 16px;
  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 12px;
  }
}

.featuresItem,
.returns {
}

.featuresItem strong {
  font-weight: 500;
}

.description,
.condition {
}

.sectionMap {
  order: 2;

  @media (--viewportMedium) {
    margin-bottom: 0;
  }
}

.locationTitle {
  /* Font */
  @apply --marketplaceH3FontStyles;
}

.sectionReviews {
  margin-bottom: 5px;

  @media (--viewportLarge) {
    margin-bottom: 3px;
  }
}

.reviewsHeading {
  @apply --marketplaceH3FontStyles;
  color: var(--matterColorAnti);

  margin: 52px 0 20px 0;

  @media (--viewportMedium) {
    margin: 53px 0 27px 0;
  }
  @media (--viewportLarge) {
    margin: 50px 0 26px 0;
  }
}

.sectionHost {
  position: relative;
  margin-bottom: 5px;

  @media (--viewportLarge) {
    margin-bottom: 3px;
  }
}

.hostInfo {
  color: var(--matterColorDark);
}

.hostInfo > span {
  font-weight: 500;
}

.hostInfo a {
  color: var(--marketplaceColorDark);
  text-decoration: underline;
  transition: var(--transitionStyleButton);

  &:hover {
    color: var(--marketplaceColor);
  }
}

.yourHostHeading {
  @apply --marketplaceH3FontStyles;
  color: var(--matterColorAnti);

  margin: 54px 0 23px 0;

  @media (--viewportMedium) {
    margin: 53px 0 27px 0;
  }
  @media (--viewportLarge) {
    margin: 53px 0 27px 0;
  }
}

.enquiryModalContent {
  flex-grow: 1;
  display: flex;
}

.enquiryForm {
  flex-grow: 1;
  margin: 70px 0 100px 0;

  @media (--viewportMedium) {
    display: flex;
    flex-direction: column;
    min-height: 400px;
    margin: 0;
  }
}

.enquirySubmitButtonWrapper {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 24px;
  background-color: var(--matterColorLight);

  @media (--viewportMedium) {
    position: static;
    padding: 0;
    margin-top: auto;
  }
}

.map {
  /* Dimensions: Map takes all available space from viewport (excludes action button and section title) */
  height: calc(100vh - 193px);
  width: 100%;

  /* Static map: dimensions are 640px */
  /* max-width: 640px;
  max-height: 640px; */
  background-color: #eee;
  padding: 0;
  border: 0;
  cursor: pointer;

  @media (--viewportMedium) {
    height: 75vh;
  }
}

.sectionRelated {
  @apply --marketplaceContainerStyles;
  margin-block: var(--spacing-s);
  @media (--viewportMedium) {
    margin-block: var(--spacing-m);
  }
}

.sectionRelatedHeader {
  @apply --marketplaceH2FontStyles;
  text-align: center;
}

.listingCards {
  @apply --gridStyle;
}

.pinContainer {
  color: var(--matterColorDark);
  display: flex;
}

.longWord {
  /* fallback option */
  word-break: break-all;
  /* use break-word if available */
  word-break: break-word;
  hyphens: auto;
}

.footer {
  margin: 0;
}

.smallScreen {
  display: flex;

  @media (--viewportMedium) {
    display: none;
  }
}

.collection,
.returns {
  display: block;
}

.collectionOptionWrap {
  display: block;
  text-align: left;
  width: 100%;
}

.collectionOptionWrap label {
  font-weight: var(--fontWeightRegular);
}

.sectionHeadingWrap {
  margin-block-start: var(--spacing-s);
  @media (--viewportMedium) {
    margin-block-start: 0;
  }
}

.collectionOption {
  @apply --marketplaceH5FontStyles;
  font-weight: 400;
  cursor: pointer;
  display: inline-block;

  & input {
    display: inline-block;
    width: auto;
    margin-right: 8px;
  }

  &:first-child {
    margin-right: 16px;
  }
}

.checkboxRoot {
  display: inline-block;
  position: relative;

  &:not(:last-child) {
    margin-right: 16px;
  }
}

.checkboxInput {
  position: absolute;
  opacity: 0;
  height: 0;
  width: 0;

  /* Highlight the borders if the checkbox is hovered, focused or checked */
  &:hover + label .box,
  &:checked + label .box {
    stroke: var(--marketplaceColor);
  }

  /* successColor version */
  &:hover + label .boxSuccess,
  &:focus + label .boxSuccess,
  &:checked + label .boxSuccess {
    stroke: var(--successColor);
  }

  /* Display the "check" when checked */
  &:checked + label .checked {
    display: inline;
    stroke: var(--marketplaceColor);
    stroke-width: 1px;
  }

  /* Display the "check" when checked */
  &:checked + label .checkedSuccess {
    display: inline;
    stroke: var(--successColor);
    stroke-width: 1px;
  }

  /* Hightlight the text on checked, hover and focus */
  &:focus + label .text,
  &:hover + label .text,
  &:checked + label .text {
    color: var(--matterColorDark);
  }
}

.checkboxLabel {
  display: flex;
  align-items: center;
  padding: 0;
}

.checkboxWrapper {
  /* This should follow line-height */
  height: 32px;
  margin-right: 12px;
  align-self: baseline;

  display: inline-flex;
  align-items: center;
  cursor: pointer;

  @media (--viewportMedium) {
    height: 20px;
    margin-right: 8px;
  }
}

.checked {
  display: none;
  fill: var(--marketplaceColor);
}
.checkedSuccess {
  display: none;
  fill: var(--successColor);
}

.collectionOptionSelect {
  width: 100%;
  @media (--viewportMedium) {
    display: inline-block;
    max-width: 50%;
  }
}

.boxSuccess,
.box {
  stroke: var(--matterColorAnti);

  @media (--viewportMedium) {
    stroke: var(--matterColorDark);
  }
}

.text {
}
.textRoot {
  color: var(--matterColor);
  margin-top: -1px;
  margin-bottom: 1px;
  cursor: pointer;
}

.mapIconText {
  font-size: 17px;
  color: #000000;
  font-weight: var(--fontWeightBold);
  padding-right: 10px;
  margin: 0;
}

.mapIcon {
  height: 27px;
  & :global(.react-toggle) {
    touch-action: pan-x;

    display: inline-block;
    position: relative;
    cursor: pointer;
    background-color: transparent;
    border: 0;
    padding: 0;

    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-tap-highlight-color: transparent;
  }

  & :global(.react-toggle-screenreader-only) {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }

  & :global(.react-toggle--disabled) {
    cursor: not-allowed;
    opacity: 0.5;
    -webkit-transition: opacity 0.25s;
    transition: opacity 0.25s;
  }

  & :global(.react-toggle-track) {
    width: 43px;
    height: 21px;
    padding: 0;
    border-radius: 30px;
    background-color: #4d4d4d;
    -webkit-transition: all 0.2s ease;
    -moz-transition: all 0.2s ease;
    transition: all 0.2s ease;
  }

  &
    :global(.react-toggle:hover:not(.react-toggle--disabled)
      .react-toggle-track) {
    background-color: #000000;
  }

  & :global(.react-toggle--checked .react-toggle-track) {
    background-color: var(--marketplaceColor);
  }

  &
    :global(.react-toggle--checked:hover:not(.react-toggle--disabled)
      .react-toggle-track) {
    background-color: var(--marketplaceColor);
  }

  & :global(.react-toggle-track-check) {
    position: absolute;
    width: 14px;
    height: 10px;
    top: 0px;
    bottom: 0px;
    margin-top: auto;
    margin-bottom: auto;
    line-height: 0;
    left: 8px;
    opacity: 0;
    -webkit-transition: opacity 0.25s ease;
    -moz-transition: opacity 0.25s ease;
    transition: opacity 0.25s ease;
  }

  & :global(.react-toggle--checked .react-toggle-track-check) {
    opacity: 1;
    -webkit-transition: opacity 0.25s ease;
    -moz-transition: opacity 0.25s ease;
    transition: opacity 0.25s ease;
  }

  & :global(.react-toggle-track-x) {
    position: absolute;
    width: 10px;
    height: 10px;
    top: 0px;
    bottom: 0px;
    margin-top: auto;
    margin-bottom: auto;
    line-height: 0;
    right: 10px;
    opacity: 1;
    -webkit-transition: opacity 0.25s ease;
    -moz-transition: opacity 0.25s ease;
    transition: opacity 0.25s ease;
  }

  & :global(.react-toggle--checked .react-toggle-track-x) {
    opacity: 0;
  }

  & :global(.react-toggle-thumb) {
    transition: all 0.5s cubic-bezier(0.23, 1, 0.32, 1) 0ms;
    position: absolute;
    top: 1px;
    left: 1px;
    width: 19px;
    height: 19px;
    border: 1px solid #4d4d4d;
    border-radius: 50%;
    background-color: #fafafa;

    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;

    -webkit-transition: all 0.25s ease;
    -moz-transition: all 0.25s ease;
    transition: all 0.25s ease;
  }

  & :global(.react-toggle--checked .react-toggle-thumb) {
    left: 23px;
    border-color: var(--marketplaceColor);
  }
}

.buttonContainer {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  margin-top: 10px;
}

.map > div {
  max-width: initial !important;
}

.mapContainer {
  @media (--viewportLarge) {
    padding: 0 0 0 0;
  }
}

.showMapMobile {
  display: inline;
  @media (--viewportMedium) {
    display: none;
  }
}

.listingCardContainer {
  display: contents;

  @media (--viewportMedium) {
    &:last-child {
      display: none;
    }
  }
  @media (--viewportLarge) {
    &:last-child {
      display: contents;
    }
  }
}

.heartContainer {
  display: flex;
  position: absolute;
  z-index: 10;
  top: 0;
  right: 0;
  padding: 5px;
}

.positionInitial {
  position: initial;
}

.dark {
  color: #000000;
}

.visuallyhidden {
  @apply --visuallyhidden;
}

.mapToggle {

}