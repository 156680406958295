@import '../../marketplace.css';

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */
}

.root {

  display: none;

}



[aria-expanded="true"] .container  {
  display: flex;
  margin: 10px 0 10px 20px;
  background-color: #fff;
  border: 1px #dead2c solid;
  border-radius: 50px;
  width: 100%;
  margin-block: auto;

}

:global(.has-search-opened) .container  {
  @media (--viewportMedium) {
    display: flex;
    margin: 10px auto;
    background-color: #fff;
    border: 2px #d8dae5 solid;
    border-radius: 50px;
    width: 100%;
    max-width: 780px;
    font-size: 1.25em;
    transition: border-color 0.25s ease-out;
    &:focus-within {
      border-color: #707070;
      transition: none;
    }
    @media (--viewportLarger) {
      margin: 20px auto;

    }
  }
}

.input {
  order: 2;
  font-size: unset;
  font-family: unset;
  border: 0;
  width: 100%;
  outline: none;
  padding: 6px 12px 6px 0;

  &::placeholder {
    color: var(--c-grey-400);
  }
}

.submitButton {
  appearance: none;
  border: 0;
  width: 3em;
}

.submitButtonLabel {
  @apply --visuallyhidden;
}

.icon {
  width: 20px;
  height: 20px;
  @media (--viewportMedium) {
    width: 30px;
    height: 30px;
  
  }
}
