@import '../../marketplace.css';
@import '../Filters/Filters.css';

.root {
  position: relative;
  z-index: 1;
  display: flex;
  flex-flow: row nowrap;
  align-items: baseline;
  justify-content: flex-end;
  gap: 8px;
  font-size: 14px;
  line-height: 1.2;
  font-weight: 500;
  width: auto;
  min-width: 230px;
}

.root label {
  font: inherit;
  white-space: nowrap;
}

.select select {
  appearance: none;
  padding: 8px 50px 8px 16px;
  border: 2px var(--c-grey-100) solid;
  font: inherit;
  /* width: auto; */
  outline: 0;
  &:hover {
    border-color: var(--c-grey-200);
  }
  &:active {
    border-color: var(--c-grey-200);
  }
  &:focus {
    border-color: var(--c-grey-200);
  }
}

.select option {
  font-weight: 400;
}
