@import '../../marketplace.css';

.ctaColumnsSizer {
  padding: 17px 0;
  @media (--viewportMedium) {
    padding: 0 0;
    padding-bottom: 22%;
    position: relative;
  }
}

.ctaColumns {
  border: 2px solid var(--marketplaceColor);

  @media (--viewportMedium) {
    bottom: 0;
    display: flex;
    height: auto;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  }
}

.column {
  border-bottom: 2px solid var(--marketplaceColor);
  color: var(--marketplaceColor);
  display: flex;
  flex-direction: column;
  font-size: 24px;
  font-weight: 500;
  height: 200px;
  justify-content: center;
  line-height: 1.3;
  padding: 0 0px;
  text-align: center;
  width: 100%;

  @media (--viewportMedium) {
    border-bottom: 0;
    border-right: 2px solid var(--marketplaceColor);
    font-size: 20px;
    height: 100%;
    padding: 0 20px;
    width: 33.333%;
  }

  @media (--viewportLarge) {
    font-size: 24px;
    padding: 0 30px;
  }

  @media (--viewportLarger) {
    font-size: 26px;
  }
}

.column:last-child {
  border-bottom: 0;

  @media (--viewportMedium) {
    border-right: 0;
  }
}

.columnInner {
  /* Element to align content in columns */
}

.column a {
  text-decoration: underline;
}
