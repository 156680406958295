@import '../../marketplace.css';

.root {
  display: none;

  @media (--viewportMedium) {
    display: flex;
    justify-content: flex-end;
    height: 57px;
    align-items: flex-end;
    padding: 13px 24px 0 24px;
    margin-top: 72px;
  }

  @media (--viewportLarge) {
    padding: 0 36px;
  }
}

.tab {
  display: flex;
  align-items: flex-end;
  height: 100%;

  &:first-child {
    margin-left: 0;
  }
}

.TabNavHorizontal_user {
  height: 69px;
  padding-left: 0px;
  padding-right: 0px;
  justify-content: space-between;
  margin-top: 0px;
}
