@import '../../marketplace.css';

.root {
  width: 100%;
  height: 100%;
  /* background-color: var(--matterColorNegative); */
}

.mapRoot {
  width: 100%;
  height: 100%;
}
