@import '../../marketplace.css';

:root {
  --oc-white: #fff;
  --oc-blue: #52abce;
  --oc-pale-blue: #a8d5e6;
  --oc-dark-blue: #104f68;
  --oc-success: #52b374;

  --sectionPaddingLarge: 64px;
  --sectionPaddingSmall: 32px;

  --font-desktop-large: 500 1rem/1.25 var(--fontFamily);
  --font-mobile-large: 500 0.875rem/1.286 var(--fontFamily);
}

@custom-media --viewportMaxMedium (max-width: 768px);

.pageTitle {
  text-align: center;
  color: var(--marketplaceColor);
}

.wrapper {
  @apply --marketplaceContainerStyles;
  margin-bottom: var(--sectionPaddingLarge);
  font-size: 0.875rem;

  @media (--viewportMedium) {
    font-size: 1.125rem;
    line-height: 1.3;
  }

  @media (--viewportMaxMedium) {
    padding-inline: 0;
  }
}

.wrapper p {
  font-weight: unset;
  /* font-size: inherit; */
  font-size: unset;
  max-width: 65ex;
  margin-right: auto;
  margin-left: auto;
}

.wrapper a:not(.button) {
  color: inherit;
}

.wrapper p {
  margin-top: 0;
  margin-bottom: 0;
}

.wrapper p + p {
  margin-top: 1.3em;
}

.container {
  display: flex;
  flex-flow: column nowrap;
  gap: 1em;
  /* 
  max-width: 42rem;
  margin: 0 auto; */
}

.header,
.footer {
  display: flex;
  flex-flow: column;
  gap: var(--sectionPaddingSmall);

  padding: var(--sectionPaddingSmall);

  background: var(--oc-blue) url('./octoplus-waves.svg') no-repeat 50% 0;
  background-size: 100% auto;

  color: var(--oc-white);

  text-align: center;
  font: var(--font-mobile-large);
  letter-spacing: -0.01em;
  @media (--viewportMedium) {
    font: var(--font-desktop-large);
    font-size: 1.25rem;
    padding: var(--sectionPaddingLarge);
    gap: calc(var(--sectionPaddingLarge) / 2);
  }
}

.header :last-child {
  margin-bottom: 0;
}

.headerIntro br {
  @media (--viewportMedium) {
    display: none;
  }
}

.verticallySpaced {
  padding: var(--sectionPaddingSmall);

  text-align: center;
  font: var(--font-mobile-large);
  color: var(--oc-blue);

  @media (--viewportMedium) {
    padding: var(--sectionPaddingLarge);
    font: var(--font-desktop-large);
  }
}

.octoplusLogo {
  max-width: 180px;
  width: 100%;
  margin: 0 auto;
  @media (--viewportMedium) {
    max-width: 360px;
  }
}

.octoplusLogo img {
  max-width: 100%;
  height: auto;
}

.button {
  background: var(--oc-blue);
  border: 0;
  color: var(--oc-white);
  display: inline-block;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.1em;
  line-height: 1.3;
  padding: 0.75em 1em;
  text-align: center;
  text-transform: uppercase;
  width: 100%;
  @media (--viewportMedium) {
    font-size: 18px;
    width: auto;
  }

  @media (--viewportLarge) {
    font-size: 20px;
    min-width: 18.9em;
  }

  &:hover {
    background: var(--oc-dark-blue);
    color: var(--oc-white);
    text-decoration: none;
  }
}

.button + .button {
  margin: 1em 2em;
}

.buttonSecondary {
  background-color: var(--oc-pale-blue);
  color: var(--oc-dark-blue);
}

.buttonWhite {
  background-color: var(--oc-white);
  color: var(--oc-blue);
}

.perks {
  background-color: #cfe5ed;
}

.perksHeader {
  padding: 2rem;
  background: var(--oc-blue) url('./octoplus-waves.svg') no-repeat 50% 50%;
  background-size: 100% auto;

  color: var(--oc-white);

  text-align: center;
  font-weight: 500;
  @media (--viewportMedium) {
    padding: 2rem;
  }
}

.perksList {
  display: grid;
  grid-template-columns: 1fr;
  gap: 1rem;
  margin: 0;

  @media (--viewportSmall) {
    grid-template-columns: repeat(2, 1fr);
    padding: 1.25rem;
    gap: 1.25rem;
  }

  @media (--viewportLargeWithPaddings) {
    grid-template-columns: repeat(3, 1fr);
  }
}

.perksList li {
  position: relative;
  width: 100%;
}

.perkCard {
  background-color: #d2e7ef;
  display: flex;
  flex-flow: column nowrap;
  transition: 0.1s ease-in-out;
  max-width: 840px;

  margin-right: calc(-1 * var(--sectionPaddingSmall));
  margin-left: calc(-1 * var(--sectionPaddingSmall));

  padding: 14px 0;
  @media (min-width: 350px) {
    flex-flow: row nowrap;
  }
  @media (--viewportMedium) {
    width: 100%;
    margin: 0 auto;
    padding: 14px;
  }
}

.perksList .perkCard {
  cursor: pointer;
  background-color: var(--oc-blue);
  margin: 0;
  padding: 0;

  &:hover {
    transform: scale(1.02);
  }
}

.perkCard a {
  cursor: pointer;

  &:after {
    content: '';
    display: block;
    position: absolute;
    inset: 0;
    z-index: 2;
    cursor: pointer;
  }

  &:hover {
    text-decoration: none;
  }
}

.perkCardInfo {
  flex-shrink: 0;
  display: flex;
  order: 2;

  @media (min-width: 350px) {
    order: 1;
    width: 31%;
    flex-flow: column;
  }
}

.perkCardLogo {
  width: 50%;
  background-color: var(--oc-white);
  display: flex;
  align-items: center;
  @media (min-width: 350px) {
    height: 50%;
    width: auto;
  }
}

.perkCardLogo img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.perkCardImage {
  position: relative;
  aspect-ratio: 1;
  order: 1;
  flex-shrink: 0;

  @media (min-width: 350px) {
    order: 2;
    width: 69%;
  }
}

.perkCardImage img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.perkCardCopy {
  width: 50%;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  text-align: center;
  font-size: 10px;
  font-size: clamp(10px, 2vw, 24px);
  line-height: 1.3;
  font-weight: 500;
  color: var(--oc-white);
  background-color: var(--oc-blue);
  @media (min-width: 350px) {
    width: auto;
    height: 50%;
  }
}

.perksList .perkCardCopy {
  @media (--viewportMedium) {
    font-size: 10px;
  }
}

.perkCardCopyDiscount {
  font-size: 2em;
}

.perkActions {
  list-style: none;
  padding: 0;
  display: flex;
  flex-flow: column;
  gap: var(--sectionPaddingSmall);
  justify-content: center;
  @media (--viewportMedium) {
    gap: var(--sectionPaddingLarge);
    flex-flow: row;
  }
}

.testimonial {
  margin: 0 auto;
  width: 100%;
  max-width: 830px;
}

.testimonial:before,
.testimonial:after {
  content: '';
  display: block;
  width: 44px;
  height: 36px;
  background: transparent url('./quotation-mark.svg') no-repeat 0 0;
  background-size: 100% 100%;
  @media (--viewportMedium) {
    width: 100px;
    height: 80px;
  }
}

.testimonial:before {
}

.testimonial:after {
  margin-left: auto;
  margin-top: -1.25rem;
  transform: rotate(180deg);
  @media (--viewportMedium) {
    margin-top: -1.65rem;
  }
}

.testimonialQuote {
}

.testimonialAuthor {
  font-size: 0.6rem;
  font-weight: 300;
  margin-top: 10px;
  @media (--viewportMedium) {
    font-size: 0.75rem;
    margin-top: 15px;
  }
}

.modal {
  position: fixed;
  display: grid;
  justify-content: center;
  align-content: center;
  inset: 0;
  z-index: 100;
  background-color: rgba(0, 0, 0, 0.3);
}

.modalContent {
  position: relative;
  max-width: 680px;
  margin: auto;
  background-color: var(--oc-white);
  padding: var(--sectionPaddingSmall);
  display: flex;
  flex-flow: column;
  gap: 1rem;
  @media (--viewportMedium) {
    padding: var(--sectionPaddingLarge);
  }
}

.modalClose {
  position: absolute;
  top: 0;
  right: 0;
  width: var(--sectionPaddingLarge);
  height: var(--sectionPaddingLarge);
}
.discountCode {
  display: flex;
  align-items: stretch;
}

.discountCode .button {
  min-width: auto;
  width: auto;
  position: relative;
  display: flex;
  flex-flow: column;
  justify-content: center;
}

.discountCode .button:after {
  content: '✓';
  position: absolute;
  inset: 0;
  z-index: 2;
  background-color: var(--oc-success);
  padding: 0.75em 1em;
  text-align: center;
  opacity: 0;
  transition: opacity 1s ease-out;
  transition-delay: 1s;
}

.discountCode .button:active:after {
  opacity: 1;
  transition: none;
}

.discountCode input[type='text'] {
  border: 0;
  background-color: #ececec;
  padding: 0.75em 1em;
}

.note {
  font-size: 0.65rem;
  font-weight: normal;
  color: inherit;
}
