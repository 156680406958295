@import '../../marketplace.css';

.root {
  fill: var(--c-black);
}

.icon {
  width: 1em;
  height: 1em;
}
