@import '../../octopusClub.css';

.error {
  margin-top: 5px;

  @media (--viewportMedium) {
    margin-top: 4px;
  }
  @media (--viewportLarge) {
    margin-top: 3px;
  }
}

.error {
  color: var(--failColor);
}

.header {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-s);
  align-items: center;
  margin-block-end: var(--spacing-s);
  @media (--viewportMedium) {
    gap: var(--spacing-s);
    margin-block-end: var(--spacing-m);
  }
}

.headerTop {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: var(--spacing-s);
  @media (--viewportMedium) {
    flex-direction: row;
    gap: var(--spacing-m);
  }
}

.avatar {
  flex-shrink: 0;
  @media (--viewportMedium) {
  }
}

.headerCopy {
  display: flex;
  flex-flow: column;
  align-items: center;
  gap: var(--spacing-xs);
  @media (--viewportMedium) {
    gap: var(--spacing-s);
    align-items: flex-start;

  }
}
.title {
  margin: 0;
}

.investor {
  display: flex;
  align-items: center;
  gap: .5em;
  font-size: 0.75em;
  font-weight: var(--fontWeightSemiBold);
  @media (--viewportMedium) {
    font-size: 0.6em;
  }
}

.investorBadge {
  display: inline-block;
  width: 16px;
  height: 16px;
  border-radius: 8px;
  background: var(--c-blue-500) url("data:image/svg+xml,%3Csvg fill='none' height='14' width='14' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m5.828 9.364.708-.707m0 0L11.192 4M6.536 8.657l.707.707m-.707-.707L4 6.12' stroke='%23fff' stroke-width='2'/%3E%3C/svg%3E") no-repeat 50% 50%;
  background-size: 85% 85%;
}


.editLink {
  @apply --marketplaceButtonStylesSecondary;
}

.bio {
  max-width: 840px;
  /* Preserve newlines, but collapse other whitespace */
  white-space: pre-line;
  text-align: center;
}

.listingsContainer {
}

.listingsTitle {
}

.listings {
  @apply --gridStyle;
  margin: 20px auto 40px;
  width: 100%;
  max-width: var(--ContainerWidthLarge);
}

.mobileReviewsTitle {
  @apply --marketplaceH3FontStyles;
  color: var(--matterColorAnti);
  margin: 24px 0 26px 0;
}

.mobileReviews {
  margin-bottom: 36px;
}

.desktopReviews {
  margin-top: 28px;

  outline: 3px cyan dotted;
  outline-offset: 10px;
  border-radius: 10px;

  @media (--viewportMedium) {
    margin-bottom: 48px;
  }

  @media (--viewportLarge) {
    max-width: 840px;
    margin: 0 auto;
  }
}

.desktopReviewsTitle {
  @apply --marketplaceH3FontStyles;
  margin-bottom: 0;
}

.listingCard {
  height: 100%;
}

.navigationTabs {
  @media (--viewportMobileOnly) {
    overflow-x: scroll;
    -webkit-overflow-scrolling: touch;
    justify-content: flex-start;
    marfin: 0;
  }
  @media (--viewportMediumOnly) {
    overflow-x: scroll;
    -webkit-overflow-scrolling: touch;
    justify-content: flex-start;
    marfin: 0;
  }
}

.reviewsWrapper {
  margin: 48px auto;
  @media (--viewportMedium) {
    max-width: 560px;
  }
  @media (--viewportLarge) {
    max-width: 840px;
  }
}
