@import '../../marketplace.css';

.root {

}

.container {
  @apply --marketplaceContainerStyles;
  
  margin-inline: auto;
  padding-inline: var(--ContainerHorizontalPadding);


  display: flex;
  min-height: calc( 100vh - 140px);
  flex-direction: column;
  margin-block-end: var(--spacing-m);
  @media (--viewportMedium) {
    margin-block-end: var(--spacing-l);

  }
}

.layoutWrapperMain {
  text-align: center;
}

.title {
  @apply --marketplaceH1FontStyles;
}
