@import '../../marketplace.css';

.content {
  max-width: 840px;
  margin-inline: auto;
  text-align: start;
}


.title {
  @apply --visuallyhidden;
  
}
