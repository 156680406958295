@import '../../marketplace.css';

.topbar {
  z-index: 1; /* ensure that Topbar shadow overlays content background */
}

.navigation {
}

.title {
  /* Font */
  @apply --marketplaceH1FontStyles;

  /* Layout */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    padding-bottom: 10px;
    margin-top: 0;
    margin-bottom: 0;
  }

  @media (--viewportLarge) {
    padding-bottom: 0;
  }
}


.error {
  color: var(--failColor);
  margin: 23px 24px 24px 24px;

  @media (--viewportLarge) {
    margin-top: 18px;
  }
}

.noResults {
  margin-block: 1.5em;
  text-align: center;
}

.itemList {
  margin: 0 auto var(--spacing-m) aut;
  padding: 0;
  max-width: 840px;
  text-align: left;
  border-top: solid 1px var(--c-grey-100);

  @media (--viewportMedium) {
    margin: 0 auto var(--spacing-m) auto;
  }
}

@keyframes loadingSpinnerFadeIn {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.listItemsLoading {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 60px;
  width: 100%;
  opacity: 0;

  /* animation shorthand property doesn't work with local scope of CSS Modules */
  animation-duration: 1s;
  animation-name: loadingSpinnerFadeIn;
  animation-fill-mode: forwards;
}

.listItem {
  font-size: 16px;
  border-bottom: solid 1px var(--c-grey-100);
}

.item {
  display: flex;
  flex-direction: row;
  padding-block: 20px;
}

.itemLink {
  flex: 1;
  align-items: baseline;
    
  /* Layout */
  display: flex;
  
  /* Remove text underline from this link */
  text-decoration: none;
  &:hover {
    text-decoration: none;
  }

  &:hover .itemUsername {
    /* Hightlight the username when the whole item is hovered */
    color: var(--marketplaceColor);
  }
}

.itemAvatar {
  margin-right: 8px;

  @media (--viewportLarge) {
    margin-right: 15px;
  }
}

.rowNotificationDot {
  width: 6px;
  height: 6px;
  margin-top: 10px;
  margin-right: 8px;

  @media (--viewportLarge) {
    margin-right: 13px;
    width: 9px;
    height: 9px;
  }
}

.notificationDot {
  color: var(--matterColorLight);

  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: var(--failColor);
}

.itemInfo {
  /* @apply --marketplaceH5FontStyles; */
  /* font-weight: var(--fontWeightSemiBold); */

  /* Layout */
  flex-grow: 1;
  flex: 1;
  flex-direction: column;

  margin-top: 5px;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }

  color: var(--matterColor);
}

.itemUsername {
  /* Font */
  /* @apply --marketplaceH4FontStyles; */
  color: var(--matterColor);
  line-height: 18px;

  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.bookingInfoWrapper {
  /* @apply --marketplaceTinyFontStyles; */

  display: flex;
  align-items: center;
  flex-wrap: wrap;
  line-height: 14px;
  margin-top: 2px;
  padding-top: 2px;

  @media (--viewportMedium) {
    padding-top: 0px;
    margin-top: 2px;
    line-height: 16px;
  }
}

.itemPrice {
  &::before {
    font-size: 10px;
    margin: 0 6px;
    display: inline-block;
    content: '\25CF'; /* middot */
  }
}

.itemState {
  /* Font */
  font-weight: var(--fontWeightRegular);
  margin-top: 6px;
  margin-bottom: 0px;
  margin-inline-start: var(--spacing-s);
  text-align: right;
  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.lastTransitionedAt {
  @apply --marketplaceTinyFontStyles;

  margin-top: 4px;
  text-align: right;
  color: var(--matterColor);
}

.pagination {
  margin: auto 24px 0 24px;
}

/* Transaction status affects to certain font colors and weights */

.stateName {
  /* This class is empty on purpose, it is used below for banned users */
}

.stateActionNeeded {
  font-weight: var(--fontWeightSemiBold);
  color: var(--c-yellow-500);
}

.stateNoActionNeeded {
  color: var(--matterColorAnti);
}

.stateSucces {
  font-weight: var(--fontWeightSemiBold);
  color: var(--successColor);
}

.nameEmphasized {
  font-weight: var(--fontWeightSemiBold);

}

.nameNotEmphasized {
  font-weight: var(--fontWeightMedium);
}

.bookingActionNeeded {
  color: var(--matterColor);
  margin-top: 4px;

  @media (--viewportMedium) {
    margin-top: 9px;
  }
}

.bookingNoActionNeeded {
  color: var(--matterColorAnti);
}

.lastTransitionedAtEmphasized {
  color: var(--c-grey-500);
  /* font-weight: var(--fontWeightMedium); */
}

.lastTransitionedAtNotEmphasized {
  color: var(--matterColorAnti);
}

.bannedUserLink {
  & .itemUsername,
  &:hover .itemUsername,
  & .bookingInfo,
  & .stateName,
  & .lastTransitionedAt {
    color: var(--matterColorAnti);
  }
}
