@import '../../marketplace.css';

.root {
  display: flex;
  flex-flow: row wrap;
  width: 100%;
  flex-shrink: 0;
  gap: 1rem;

  font-size: 14px;
  line-height: 1.2;
  font-weight: 500;

  white-space: nowrap;
  @media (max-width: 550px) {
    flex-flow: row nowrap;
    margin-inline: -20px;
    padding-inline: 20px;
    width: 100vw;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }

  @media (--viewportMedium) {
    flex-wrap: wrap;
  }
}

.root::-webkit-scrollbar {
  display: none;
}

.root ul {
  display: flex;
  gap: 1rem;
  margin: 0;
}

.root label {
  margin: 0;
  display: flex;
  gap: 8px;
  font-size: inherit;
}

.root label span {
  display: block;
  width: 100%;
}

.root input[type='checkbox'] {
  width: auto;
}

.sale {
  color: var(--c-pink-500);
}

.mapToggle {
  margin-inline-start: auto;
  color: var(--c-yellow-600);
}

.mapToggleLabel {
  &:hover {
    text-decoration: underline;
   } 
}

.mapToggleLabel span {
  display: none;
  @media (--viewportMedium) {
    display: inline;
  }
}
