@import '../../marketplace.css';

.heart {
  width: 52px;
  height: 52px;
  padding: 10px;
  color: var(--c-pink-500);
  cursor: pointer;
  border: 0;
  appearance: none;
  position: relative;

  @media (--viewportMedium) {
    width: 72px;
    height: 72px;
    padding: 20px;
  }
}

.heart:before {
  content: '';
  display: block;
  background-color: rgba(255, 255, 255, 0.8);
  width: 32px;
  height: 32px;
  border-radius: 50%;
  position: absolute;
  inset: 0;
  margin: auto;
}

.heart svg {
  position: absolute;
  width: 16px;
  height: 16px;
  object-fit: contain;
  z-index: 3;
  inset: 0;
  margin: auto;
  fill: #555;

}

.heart[aria-pressed="true"] svg {
  fill: var(--c-pink-500);

}

.heart:hover:before {
  background-color: var(--c-pink-100);
}

.heart:hover svg {
  fill: #000;
}

.heart:active svg {
  fill: calc;
}
