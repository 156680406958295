@import '../../marketplace.css';

:root {
  --contentPaddingSmall: 20px;
  --contentGapSmall: 20px;
  --contentPaddingLarge: 60px;
  --contentGapLarge: 36px;
}

.root {
  @apply --marketplaceSmallFontStyles;
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
}

.image {
}

.image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.title {
  @apply --marketplaceH3FontStyles;
  margin: 0;
}

.copy {
  margin-block: 20px;
}

.footer {
  margin-top: auto;
}

.button {
  @apply --marketplaceButtonStyles;
  width: 100%;
  padding-inline: 0;
}

.root:nth-last-of-type(3n + 1) .button {
  @media (max-width: 768px) {
    @apply  --marketplaceButtonStylesPrimary;
    width: 100%;
    padding-inline: 0;
  }
}

.root:nth-last-of-type(2n + 1) .button {
  @media (--viewportMedium) {
    @apply  --marketplaceButtonStylesPrimary;
    width: 100%;
    padding-inline: 0;
  }
}


