@import '../../marketplace.css';

.root {
  width: 100%;
  position: sticky;
  top: 0;
  z-index: var(--zIndexTopbar);

  @media (--viewportMedium) {
    position: relative;
  }

  @media (--viewportLarger) {
    margin: 0 auto;
    max-width: var(--ContainerWidthLarge);
  }
}
