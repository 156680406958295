@import '../../marketplace.css';

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */

}

.root {
  display: flex;
  flex-direction: row;
  overflow-x: auto;
}

.tab {

}

.link {
  display: inline-block;
  white-space: nowrap;

  /* Font */
  @apply --marketplaceTabNavFontStyles;

  color: var(--matterColor);
  padding-bottom: 10px;
  padding-top: 10px;

  /* push tabs against bottom of tab bar */
  margin-top: auto;

  border-bottom-width: 2px;
  border-bottom-style: solid;
  border-bottom-color: transparent;

  transition: var(--transitionStyleButton);

  &:hover {
    text-decoration: none;
    color: var(--matterColorDark);
  }


}

.selectedLink {
  border-bottom-color: var(--matterColorDark);
  color: var(--matterColorDark);

  
}

.selectedLinkInbox {
  color: var(--marketplaceColor) !important;
}

.disabled {
  pointer-events: none;
  color: var(--matterColorAnti);
  text-decoration: none;
}
