@import '../../marketplace.css';

.root {
  /* Dimensions */
  width: 100%;
  height: auto;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;

  @media (--viewportMedium) {
    padding-top: 2px;
  }
}

.error {
  color: var(--failColor);
}

.priceInput {
  flex-shrink: 0;
  width: 100%;
  margin-bottom: 32px;
}

.submitButton {
  margin-top: auto;
  margin-bottom: 24px;

  @media (--viewportLarge) {
    display: inline-block;
    width: 241px;
    margin-top: 100px;
  }
}

.note {
  @apply --marketplaceH5FontStyles;
  flex-shrink: 0;
  color: var(--matterColorAnti);
  margin-top: 24px;
  margin-bottom: 60px;

  @media (--viewportLarge) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.note a {
  text-decoration: underline;
}

.checkboxPrice {
  margin-top: 10px;
  margin-bottom: 32px;
}

.placeholderFix {
  line-height: 52px;

  @media (--viewportMedium) {
    line-height: 28px;
  }
}

.placeholderFix::-webkit-input-placeholder {
  white-space: pre-line;
  position: relative;
  top: -10px;

  @media (--viewportMedium) {
    white-space: unset;
    position: unset;
    top: unset;
  }
}

.postageInput {
  flex-shrink: 0;
  width: 100%;
  margin-bottom: 4px;

  & + span {
    display: inline-block;
    margin-bottom: 32px;
  }
}

.condition {
  @apply --EditListingDescriptionForm_formMargins;
}

.condition > select {
  line-height: 27px;
  /* font-size: 16px; */
  overflow: hidden;
}
