@font-face {
  font-family: 'Gordita';
  src:  url('/static/fonts/Gordita-Regular.woff2') format('woff2'),
        url('/static/fonts/Gordita-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: fallback; 
}

@font-face {
  font-family: 'Gordita';
  src:  url('/static/fonts/Gordita-Medium.woff2') format('woff2'),
        url('/static/fonts/Gordita-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: fallback; 
}

@font-face {
  font-family: 'Gordita';
  src: url('/static/fonts/Gordita-Bold.woff2') format('woff2');
  font-weight: 600;
  font-style: normal;
  font-display: fallback; 
}

