@import '../../marketplace.css';

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */

  --SearchPage_containerHeight: calc(100vh - var(--topbarHeightDesktop));
}

/* Can be given to Topbar when a modal is opened
 * so that the Topbar is not rendered on top of it.*/
.topbarBehindModal {
  z-index: initial;
}

.topbar {
  width: 100%;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: var(--zIndexTopbar);

  @media (--viewportMedium) {
    position: relative;
    top: auto;
  }
}

.container {
  width: 100%;
}

.searchResultContainer {
  display: flex;
  min-height: calc( 100vh - 140px);
  flex-direction: column;
}

.error {
  color: var(--failColor);
  padding-left: 24px;
  padding-right: 24px;
}

.searchString {
  /* Search string should not break on white spaces - i.e. line-break should happen before. */
  white-space: nowrap;
}


.listings {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding: 0;
  margin: 0 ;
  padding-top: 16px;
  height: 100%;
}

.newSearchInProgress {
  opacity: 0.1;
}

.searchListingsPanel {
  flex-grow: 1;
}

.mapPanel {
  @media (--viewportMedium) {
    /**
     * .container is using flexbox,
     * This specifies that mapPanel is taking 34% from the viewport width
     */
    /* flex-basis: 50%; */

    /* Own layout settings */
    display: flex;
    height: 100%;
  }

  @media (--viewportLarge) {
    /* flex-basis: 37.5%; */
  }
}

.sortBy {
  margin-right: 9px;
}

.sortByMenuLabel {
  @apply --marketplaceButtonStylesSecondary;
  @apply --marketplaceTinyFontStyles;
  font-weight: var(--fontWeightMedium);

  height: 35px;
  min-height: 35px;
  padding: 0 18px;
  margin: 0;
  border-radius: 4px;
  white-space: nowrap;
}

.mapWrapper {
  width: 100%;
  height: 100%;
}

.map {
  width: 100vw;
  height: 100vh;

  @media (--viewportMedium) {
    /* Map is fixed so that it doesn't scroll along search results */
    top: var(--topbarHeightDesktop);
    right: 0;

    /* Fixed content needs width relative to viewport */
    width: 100%;
    height: var(--SearchPage_containerHeight);
  }
}

.mapIconText {
  font-size: 13px;
  color: var(--matterColorDark);
  font-weight: var(--fontWeightBold);
  padding-right: 5px;
  margin: 0;
}

.mapIcon {
  height: 20px;
  & :global(.react-toggle) {
    touch-action: pan-x;

    display: inline-block;
    position: relative;
    cursor: pointer;
    background-color: transparent;
    border: 0;
    padding: 0;

    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-tap-highlight-color: transparent;

    transform: scale(0.8);
  }

  & :global(.react-toggle-screenreader-only) {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }

  & :global(.react-toggle--disabled) {
    cursor: not-allowed;
    opacity: 0.5;
    -webkit-transition: opacity 0.25s;
    transition: opacity 0.25s;
  }

  & :global(.react-toggle-track) {
    width: 43px;
    height: 21px;
    padding: 0;
    border-radius: 30px;
    background-color: #4d4d4d;
    -webkit-transition: all 0.2s ease;
    -moz-transition: all 0.2s ease;
    transition: all 0.2s ease;
  }

  & :global(.react-toggle:hover:not(.react-toggle--disabled) .react-toggle-track) {
    background-color: #000000;
  }

  & :global(.react-toggle--checked .react-toggle-track) {
    background-color: var(--marketplaceColor);
  }

  & :global(.react-toggle--checked:hover:not(.react-toggle--disabled) .react-toggle-track) {
    background-color: var(--marketplaceColor);
  }

  & :global(.react-toggle-track-check) {
    position: absolute;
    width: 14px;
    height: 10px;
    top: 0px;
    bottom: 0px;
    margin-top: auto;
    margin-bottom: auto;
    line-height: 0;
    left: 8px;
    opacity: 0;
    -webkit-transition: opacity 0.25s ease;
    -moz-transition: opacity 0.25s ease;
    transition: opacity 0.25s ease;
  }

  & :global(.react-toggle--checked .react-toggle-track-check) {
    opacity: 1;
    -webkit-transition: opacity 0.25s ease;
    -moz-transition: opacity 0.25s ease;
    transition: opacity 0.25s ease;
  }

  & :global(.react-toggle-track-x) {
    position: absolute;
    width: 10px;
    height: 10px;
    top: 0px;
    bottom: 0px;
    margin-top: auto;
    margin-bottom: auto;
    line-height: 0;
    right: 10px;
    opacity: 1;
    -webkit-transition: opacity 0.25s ease;
    -moz-transition: opacity 0.25s ease;
    transition: opacity 0.25s ease;
  }

  & :global(.react-toggle--checked .react-toggle-track-x) {
    opacity: 0;
  }

  & :global(.react-toggle-thumb) {
    transition: all 0.5s cubic-bezier(0.23, 1, 0.32, 1) 0ms;
    position: absolute;
    top: 1px;
    left: 1px;
    width: 19px;
    height: 19px;
    border: 1px solid #4d4d4d;
    border-radius: 50%;
    background-color: #fafafa;

    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;

    -webkit-transition: all 0.25s ease;
    -moz-transition: all 0.25s ease;
    transition: all 0.25s ease;
  }

  & :global(.react-toggle--checked .react-toggle-thumb) {
    left: 23px;
    border-color: var(--marketplaceColor);
  }
}

.buttonContainer {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 10px;

  & + .buttonContainer {
    margin-left: 10px;
  }
}

.searchMenu {
  /* padding: 12px 24px; */
  display: inline-block;
  min-height: 36px;
  /* padding: 0 10px; */
  width: auto;

  margin-right: 12px;
  font-size: 12px;
  min-width: auto;
  padding-left: 10px;
  padding-right: 10px;
  /* min-width: 142px; */

  @media (--vp345) {
    font-size: 13px;
    min-width: auto;
  }
}

.rowEnd {
  display: flex;
  justify-content: space-between;
  width: 245px;
}
