@import '../../marketplace.css';

.root {
  @apply --marketplaceContainerNarrowStyles;

  /* Content of EditListingWizard should have smaller z-index than Topbar */
  z-index: 0;
}

.tabsContainer {
  padding-block-end: max( env(safe-area-inset-bottom), var(--spacing-m));
}

.nav {
  display: flex;
  flex-direction: row;
  overflow-x: auto;
  margin: 0 -20px;
  padding-inline: 10px;

  -webkit-overflow-scrolling: touch;
  text-align: center;

  -ms-overflow-style: none;
  scrollbar-width: none;
}

.nav::-webkit-scrollbar {
  display: none;
}

.tab {
  white-space: nowrap;
}

.tab {
}
.tab:first-child {
  margin-inline-start: auto;
}
.tab:last-child {
  margin-inline-end: auto;
}

.panel {
  /* background-color: pink; */
}

.title {
  text-align: center;
}

.payoutDetails {
  margin-bottom: 100px;

  @media (--viewportMedium) {
    margin-bottom: 0;
  }
}

.modalTitle {
  @apply --marketplaceModalTitleStyles;
}

.modalPayoutDetailsWrapper {
  @media (--viewportMedium) {
    width: 604px;
    padding-top: 11px;
  }
}

.modalMessage {
  @apply --marketplaceModalParagraphStyles;
}
