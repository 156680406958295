.ctaSizer {
  @media (--viewportMedium) {
    padding-bottom: 46%;
    position: relative;
  }
}

.cta,
.ctaFlipped {
  width: 100%;
  position: relative;
  flex-direction: column;
  display: flex;

  @media (--viewportMedium) {
    bottom: 0;
    display: flex;
    flex-direction: row;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  }
}

/* .ctaFlipped .banner {
    order: 2;
}

.cta > div:first-child {
    order: 2;
} */

.image {
  height: auto;
  overflow: hidden;
  position: relative;
  width: 100%;

  @media (--viewportMedium) {
    height: auto;
    width: 50%;
  }
}

.rootForImage {
  height: auto;
  width: 100%;

  @media (--viewportMedium) {
    height: 100%;
    width: auto;
    left: 50%;
    position: absolute;
    right: 0;
    top: 0;
    transform: translateX(-50%);
    bottom: 0;
  }
}
