@import '../../marketplace.css';

:root {
}

.root {
  @apply --marketplaceContainerStyles;
  padding-block: var(--spacing-s);
  position: relative;
  text-align: center;
}

.title {
  @apply --marketplaceH2FontStyles;
  @media (--viewportMobileOnly) {
    font-size: 24px;
    line-height: 28px;
  }
}

.cloud {
  list-style: none;
  margin-block: var(--spacing-m) 0;
  padding: 0;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
  gap: 8px;
  @media (--viewportMedium) {
    gap: 10px;
  }
}

.pill {
  padding: 0.75em 1.25em;
  background-color: #000;
  color: #fff;
  border-radius: 1.25em;
  font-size: 13px;

  line-height: 1;
  display: inline-block;
  transition: background-color 0.25s ease-out;
  &:hover {
    text-decoration: none;
  }

  @media (--viewportMedium) {
    font-size: 18px;
  }
}

.cloud li:nth-child(8n + 1) .pill,
.cloud li:nth-child(8n + 5) .pill {
  background-color: var(--c-yellow-500);
}
.cloud li:nth-child(8n + 2) .pill,
.cloud li:nth-child(8n + 4) .pill,
.cloud li:nth-child(8n + 7) .pill {
  background-color: var(--c-green-500);
}

.cloud li:nth-child(8n + 3) .pill,
.cloud li:nth-child(8n + 6) .pill,
.cloud li:nth-child(8n + 8) .pill {
  background-color: var(--c-pink-500);
}

@media (hover: hover) {
  .cloud li:nth-child(8n + 1) .pill:hover,
  .cloud li:nth-child(8n + 5) .pill:hover {
    transition: none;
    background-color: var(--c-yellow-700);
  }
  .cloud li:nth-child(8n + 2) .pill:hover,
  .cloud li:nth-child(8n + 4) .pill:hover,
  .cloud li:nth-child(8n + 7) .pill:hover {
    transition: none;
    background-color: var(--c-green-700);
  }

  .cloud li:nth-child(8n + 3) .pill:hover,
  .cloud li:nth-child(8n + 6) .pill:hover,
  .cloud li:nth-child(8n + 8) .pill:hover {
    transition: none;
    background-color: var(--c-pink-700);
  }
}
