@import '../../marketplace.css';

@custom-media --viewport1140px (min-width: 1140px);

:root {
  --NavigationLinkSyle: {
    font-size: 16px;
    display: inline-block;
    font-weight: 500;
    line-height: 1;
    padding: 0.5em;
    white-space: nowrap;
    color: var(--c-black);
    &:hover {
      text-decoration: none;
      color: var(--c-yellow-600);
    }
  }

  --SubNavigationLinkStyle {
    width: 100%;
    padding: 16px 24px;

    display: flex;
    justify-content: space-between;
    align-items: baseline;

    background-color: #fdf3db; /* should be c-yellow-100 */
    border-width: 0 0 1px 0;
    border-color: var(--c-yellow-500);
    border-style: solid;
    font-weight: var(--fontWeightMedium);
  }
}


/* Desktop */
.root {
  display: flex;
  align-items: baseline;
  width: 100%;

  font-size: 16px;
  font-weight: 500;
  line-height: 1;

}

.navigationContainer {
  display: flex;
  align-items: baseline;
}

.navigation {
  display: flex;
  flex-flow: row;
  align-items: baseline;
  margin-inline: 0;
  @media (--viewportMedium) {
    gap: 8px;
  }
}

.navigation li a {
  @apply --NavigationLinkSyle;
}

.navigationPrimary {
  margin-right: 20px;
  @media (--viewportLarge) {
    margin-right: 30px;
  }
  @media (--viewportLarger) {
    margin-right: 40px;
  }
}

[aria-expanded="true"] .navigationPrimary {
  order: 2;
}

.navigationPrimary li a {
  font-size: 1.25em;

  @media (--viewportMedium) {
    font-size: 1rem;
  }
}

.navigationTertiary {
  margin-left: auto;
  gap: 9px;
  align-items: center;

  @media (--viewportLarger) {
    gap: 18px;
  }
  @media (--viewportSmall) {
    font-size: 16px;
  }
}


[aria-expanded="true"] .navigationTertiary {
  display: none;
}

.navigationTertiaryMobile {
  display: none;
}

[aria-expanded="true"] .navigationTertiaryMobile {
  order: 4;
}



.navigationTertiary--mobile {
  display: none;
}

.navigationPrimary,
.navigationSecondary {
  display: flex;
  align-items: baseline;
}

.navigationPrimary,
.navigationSecondary {
  display: none;
  @media (--viewportLarge) {
    display: flex;
  }
}

.navigationPrimary,
.navigationSecondary {
  display: none;
  @media (--viewportLarge) {
    display: flex;
  }
}


.navigation {
  @media (--viewportMedium) {
    gap: .5em;
  }
}

.navigationButton {
  appearance: none;
  margin: unset;
  background: none;
  border: none;
  font-size: unset;
  font-family: unset;
  font-weight: 500;
  color: #000;
  font-size: 16px;
  white-space: nowrap;
}

.userAvatarButton {
  padding: 4px;
}

/* Profile menu */
.profileMenuLabel {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  gap: 10px;
}

/* Authentication */
.signupLink {
  @apply --NavigationLinkSyle;
}

.loginLink {
  @apply --NavigationLinkSyle;
}

.basicLink {
  flex-shrink: 0;
  font-size: 18px;
  font-weight: 500;
  height: auto;
  padding: 0;

  color: var(--matterColorDark);

  &:hover {
    color: var(--marketplaceColor);
  }
}

.basicLink:first-of-type {
  margin-left: 0;
}

.signup,
.login {
  @apply --marketplaceH4FontStyles;
  @apply --NavigationLinkSyle;
}

.basicLinkInner {
  @apply --marketplaceH4FontStyles;
  @apply --NavigationLinkSyle;
}

/* left animated "border" like hover element */
.menuItemBorder {
  position: absolute;
  top: 2px;
  left: 0px;
  height: calc(100% - 4px);
  width: 0;
  transition: width var(--transitionStyleButton);
}

.profileSettingsLink,
.yourListingsLink {
  @apply --marketplaceH4FontStyles;
  position: relative;
  display: block;

  /* Dimensions */
  width: 100%;
  margin: 0;
  padding: 4px 24px;

  /* Layout details */
  color: var(--matterColor);
  text-align: left;
  transition: var(--transitionStyleButton);

  &:hover {
    color: var(--matterColorDark);
    text-decoration: none;

    & .menuItemBorder {
      width: 6px;
      background-color: var(--marketplaceColor);
    }
  }

  @media (--viewportMedium) {
    margin: 0;
  }
}

.logoutButton {
  @apply --SubNavigationLinkStyle;
  font-size: 16px;
  line-height: 1;
  appearance: none;
  background-color: var(--c-white);

}

.root[aria-expanded="true"] .logoutButton {
  background-color: var(--c-white);
}
.navigationButton[aria-expanded="true"] + ul .logoutButton {
  background-color: #fdf3db;
}

.searchToggle {
  appearance: none;
  border: none;
  padding: 8px;
  @media (--viewportMobileOnly) {
    display: block;
  }
}
 
.searchToggleText {
  @apply --visuallyhidden;
}

.displayName {
  display: none;
  @media (--viewport1140px) {
    display: block;
    margin-left: 10px;
    font-weight: 500;
    font-size: 16px;
    pointer-events: none;

    max-width: 10ch;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  @media (--viewportLarger) {
    max-width: 15ch;
  }
}

/* Subnav */

.navigation ul {
  position: absolute;
  display: none;
  padding-top: 8px;
  margin: 0 8px;
}

[aria-expanded="true"] .navigation ul {
  position: relative;
  padding: 0;
  margin: 0;
}

.navigation li {
  position: relative;
}

@media screen and (max-width: 1024px) {
  [aria-expanded="true"] .navigationContainer :is(a, button) {
    border-bottom: 1px solid var(--c-grey-100);
    width: 100%;
    padding: 14px 0;
  }
}

[aria-expanded="true"] .navigation:not(.navigationTertiary) {
  border-top: 1px solid var(--c-grey-100);
  margin-bottom: 50px;
}

[aria-expanded="true"] .navigationContainer :is(a, button) {
  border-bottom: 1px solid var(--c-grey-100);
  width: 100%;
  padding: 14px 0;
}

@media screen and (min-width: 768px) {
  .navigation ul :is(a, button) {
    background-color: #fdf3db;
    min-width: 16ch;
  }
}

.navigation li [aria-expanded='true'] ~ ul {
  display: block;
}

.subNavigationUser {
  right: 0;
  margin-inline-end: 0;
}

@media screen and (min-width: 1270px) {
  .nav:last-of-type ul {
    right: 0;
  }
}

.navigation ul a {
  @apply --SubNavigationLinkStyle;
}

[aria-expanded="true"] .navigation ul li a {
  padding: 16px 16px;
  width: auto;
}

[aria-expanded="true"] .navigation ul li a {
  border-color: #fff;
}

[aria-expanded="true"] .navigation ul a:after {
  display: none;
}

.navigation :is(a, button):focus {
  position: relative;
  z-index: 1;
}

@media (hover: hover) {
  .navigation li:hover > :is(a, button) {
    color: #c08f1f;
  }
}

li a.active {
  color: #c08f1f;
}

.navigation [aria-haspopup='true'][aria-expanded='true'] {
  color: #c08f1f;
  border: #fff;
}


.navigation button[aria-haspopup='true'][aria-expanded='true']:after {
  rotate: -90deg;
  transition-duration: 0.1s;
}

.navigation button[aria-haspopup='true'] {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.navigation button[aria-haspopup='true']:after {
  @media (--viewportMedium) {
    background-color: currentColor;
    -webkit-mask-image: var(--icon-arrow);
    mask-image: var(--icon-arrow);
    content: '';
    display: block;
    width: 12px;
    height: 12px;
    rotate: 90deg;
    margin-inline-start: 0.5em;
    transition: rotate 0.2s ease-out;
  }
}

@media (prefers-reduced-motion: no-preference) {
  .navigation button[aria-haspopup='true']:after {
    transition: rotate 0.2s ease-out;
  }
}

[aria-expanded="true"] .navigation button[aria-haspopup='true']:after {
  margin-right: 3px;
}

.toggle {
  width: 56px;
  z-index: 11;
  position: relative;
  cursor: pointer;
  align-self: stretch;
  @media (--viewportLarge) {
    display: none;
  }
}

[aria-expanded="true"] .toggle {
  @media (--viewportLarge) {
    display: block;
  }
}


.toggle span {
  display: block;
  width: 24px;
  height: 2px;
  border-radius: 2px;
  position: absolute;
  right:0;
  left:0;
  top:50%;
  margin: auto;
  background: #000;
  transition: all .25s ease-in-out;
  transform-origin: 50% 50%;
}

.toggle span:nth-child(1) {
  transform: translateY(-11px) ;
}

.toggle span:nth-child(3) {
  transform: translateY(11px) ;
}

.toggle[aria-pressed="true"]  {
}


.toggle[aria-pressed="true"]  span:nth-child(1) {
  transform: translateY(0) rotate(-45deg);
}

.toggle[aria-pressed="true"]  span:nth-child(2) {
 opacity: 0;
}

.toggle[aria-pressed="true"] span:nth-child(3) {
  transform: translateY(0) rotate(45deg);

}

/* Search */



.searchPanel {
  display: none;
  align-self: stretch;
}

:global(.has-search-opened) .searchPanel {
  display: flex;
  @media (--viewportMedium) {
    position: absolute;
    inset: 0;
    z-index: 2;
  }
}

:global(.has-search-opened) .navigation {
  display: none;
}

[aria-expanded="true"] .searchPanel {
  display: flex;
}

[aria-expanded="true"],
[aria-expanded="true"] body {
  overflow: hidden;
}

[aria-expanded="true"] .navigationContainer {
  flex-flow: column nowrap;
  align-items: flex-start;
  overflow: hidden;
  overflow-y: scroll;
  flex: 0 1 100%;
  padding: 0 20px;
  height: 100%;
  grid-area: middle;
}

[aria-expanded="true"] .navigation {
  gap: 0;
}

[aria-expanded="true"] .navigation:not(.navigationTertiary) {
  display: flex;
  flex-flow: column nowrap;
  width: 100%;
  align-items: stretch;
  margin: 0 0 50px 0;
}

[aria-expanded="true"] .navigationSecondary {
  order: 3;
}
[aria-expanded="true"] .navigationTertiary {
  display: none;
}
[aria-expanded="true"] .navigationTertiary--mobile {
  order: 4;
}

[aria-expanded="true"] .toggle {
  grid-area: toggle;
}

[aria-expanded="true"] .icon-toggle {
  background: none;
}

[aria-expanded="true"] .icon-toggle:before {
  top: 11px;
  rotate: 45deg;
}
[aria-expanded="true"] .icon-toggle:after {
  bottom: 11px;
  rotate: -45deg;
}

[aria-expanded="true"] .masthead-search {
  display: flex;
  order: 1;
  /* flex-grow: 0; */
  width: 100%;
  grid-area: search;
  visibility: visible;
}

/* Mobile footer */
.footer {
  display: none;
}

.footer a {
  @apply --marketplaceButtonStylesPrimary;
  display: block;
}

[aria-expanded="true"] .footer {
  padding: 20px;
  z-index: 3;
  display: block;
  order: 5;
  grid-area: bottom;
}

.toggle {
  appearance: none;
  border: none;
}

.toggleLabel {
  @apply --visuallyhidden;
}

.closeSearchButton {
  display: none;
  border: 0;
  position: relative;
  z-index: 10;
}

.closeSearchButtonLabel {
  @apply --visuallyhidden;
}

:global(.has-search-opened) .closeSearchButton {
  display: block;
}

/* Notifications */

.notificationDot {
  background-color: var(--c-pink-500);
  color: #fff;
  padding: 0.5em;
  border-radius: 1em;
  min-width: 2em;
  display: inline-grid;
  place-content: center;
  font-size: 0.75em;
  line-height: 1;
}

a:hover .notificationDot {
  background-color: var(--c-yellow-500);
}

.navigationTertiary .notificationDot {
  display: grid;
  position: absolute;
  top: 1em;
  left: 2.5em;
  font-size: 0.5em;
  line-height: 1;
  padding: 0.5em;
}

.navigationTertiaryMobile .notificationDot {
  margin-inline-start: 0.5em;
}

