@import '../../marketplace.css';

.root {
}

.anchorNav {
  @apply --marketplaceContainerStyles;
}

.anchorNav ul {
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: var(--spacing-s);
  @media (--viewportMedium) {
    flex-direction: row;
    margin-block: var(--spacing-l);
    gap: var(--spacing-m);
    overflow-x: auto;
    margin-inline: -20px;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: none;
    scrollbar-width: none;
    scroll-padding-line-end: 50px;
  }
}

.anchorNav ul::-webkit-scrollbar {
  display: none;
}

.anchorNav li:first-child {
  @media (--viewportMedium) {
    margin-inline-start: auto;
    padding-inline-start:20px;

  }
}
.anchorNav li:last-child {
  @media (--viewportMedium) {
    margin-inline-end: auto;
    padding-inline-end:20px;
  }
}

.button {
  @apply --marketplaceButtonStyles;
  width: 100%;
}

.buttonPink {
  @apply --marketplaceButtonPinkStyles;
}

.buttonGreen {
  @apply --marketplaceButtonGreenStyles;
}
