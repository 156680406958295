@import '../../marketplace.css';

.content {
  @apply --marketplaceContainerNarrowStyles;
}

.heading {
  text-align: center;
}

