@import '../../marketplace.css';

.root {
  display: flex;
  flex-flow: row nowrap;
  position: relative;
  background-color: red;
  width: 100%;
  margin: 0 auto;
  max-width: var(--ContainerWidthLarge);
  padding: 0 var(--ContainerHorizontalPadding)
}
